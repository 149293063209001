@import '~xterm/css/xterm.css';

.pf-v5-c-form-control {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

dd:where(:not([class*="pf-v5-c-"])) {
  margin-bottom: var(--pf-v5-global--spacer--lg);
}

dd:last-child:where(:not([class*="pf-v5-c-"])) {
  margin-bottom: 0;
}

dl:where(:not([class*="pf-v5-c-"])) {
  margin: 0;
}

dt:where(:not([class*="pf-v5-c-"])) {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

fieldset:where(:not([class*="pf-v5-c-"])) {
  border: 0;
}

input[type=number]:where(:not([class*="pf-v5-c-"])) {
  -webkit-appearance: textfield;
  appearance: textfield;
  max-width: 100px;
}

input[type=number]:where(:not([class*="pf-v5-c-"]))::-webkit-inner-spin-button,
input[type=number]:where(:not([class*="pf-v5-c-"]))::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=radio]:where(:not([class*="pf-v5-c-"])) {
  margin-right: 7px;
}

:is(ul,ol):where(:not([class*="pf-v5-c-"])) {
  padding-left: 20px;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

.form-group {
  margin-bottom: 15px;
}

.has-feedback {
  position: relative;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(--pf-v5-global--Color--100);
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: 1px solid var(--pf-v5-global--BorderColor--100);
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio+.radio,
.checkbox+.checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  margin-right: 20px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.small,
small {
  font-size: var(--pf-v5-global--FontSize--sm) !important;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
  padding: var(--pf-v5-global--spacer--sm);
  vertical-align: top;
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: var(--pf-v5-global--BorderWidth--md) solid var(--pf-v5-global--BorderColor--300);
}

.table>caption+thead>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>th,
.table>thead:first-child>tr:first-child>td {
  border-top: 0;
}

.text-muted {
  color: var(--pf-v5-global--Color--200);
}

.text-secondary {
  color: var(--pf-v5-global--Color--200);
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

:root {
  --pf-v5-c-code-block--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-code-block__pre--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-code-block__pre--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-content--MarginBottom: var(--pf-v5-global--spacer--md);
}

h1:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--2xl);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

h2:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--xl);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

h3:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--lg);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

h4:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--md);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

h5:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--md);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

h6:where(:not([class*="pf-v5-c-"])) {
  font-size: var(--pf-v5-global--FontSize--md);
  font-family: var(--pf-v5-global--FontFamily--heading);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  word-break: break-word;
}

:where(:not([class*="pf-v5-c-"])) input,
:where(:not([class*="pf-v5-c-"])) optgroup,
:where(:not([class*="pf-v5-c-"])) select,
:where(:not([class*="pf-v5-c-"])) textarea,
:where(:not([class*="pf-v5-c-"])) .pf-v5-c-badge {
  font-family: var(--pf-v5-global--FontFamily--text);
}

button:where(:not([class*="pf-v5-c-"])) {
  font-family: var(--pf-v5-global--FontFamily--text);
}

.pf-c-dropdown__group ul,
.pf-c-select__menu-group ul {
  list-style: none;
  padding-left: 0;
}

.pf-v5-c-form-control {
  --pf-v5-c-form-control--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form-control--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-form-control--Resize: none;
  --pf-v5-c-form-control--OutlineOffset: 0;
  --pf-c-form-control--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderRightColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-form-control--BorderLeftColor: var(--pf-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderBottomWidth: 0;
  --pf-v5-c-form-control--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderBottomColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-form-control--Width: 100%;
  --pf-v5-c-form-control--inset--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-form-control--m-placeholder--child--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--after--BorderColor: transparent;
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor: transparent;
  --pf-v5-c-form-control--m-readonly--m-plain--inset--base: 0;
  --pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset: 0;
  --pf-v5-c-form-control--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-success--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-warning--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-error--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control--m-error--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control--m-icon--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--icon--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--m-icon--icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-error--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form-control__select--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__select--m-success--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-warning--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-error--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control--textarea--Width: var(--pf-v5-c-form-control--Width);
  --pf-v5-c-form-control--textarea--Height: auto;
  --pf-v5-c-form-control__icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control--m-success__icon--m-status--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-warning__icon--m-status--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-error__icon--m-status--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control__utilities--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__utilities--PaddingTop: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__utilities--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__toggle-icon--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
  color: var(--pf-v5-c-form-control--Color);
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--pf-v5-c-form-control--ColumnGap);
  align-items: start;
  width: var(--pf-v5-c-form-control--Width);
  font-size: var(--pf-v5-c-form-control--FontSize);
  line-height: var(--pf-v5-c-form-control--LineHeight);
  resize: var(--pf-v5-c-form-control--Resize);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
}

.pf-v5-c-form-control:is(input,select,textarea) {
  grid-row: 1/2;
  grid-column: 1/-1;
  padding-block-start: var(--pf-v5-c-form-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-form-control--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form-control--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control--PaddingRight);
  color: var(--pf-v5-c-form-control--Color);
  border: var(--pf-global--BorderWidth--sm) solid;
  border-color: var(--pf-c-form-control--BorderTopColor) var(--pf-c-form-control--BorderRightColor) var(--pf-c-form-control--BorderBottomColor) var(--pf-c-form-control--BorderLeftColor);
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pf-v5-c-form-control:is(input,select,textarea):focus {
  outline-offset: var(--pf-v5-c-form-control--OutlineOffset);
}

.pf-v5-c-form-control::placeholder {
  color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control:is(input,select) {
  text-overflow: ellipsis;
}

.pf-v5-c-form-control.pf-m-readonly {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--BackgroundColor);
}

.pf-v5-c-form-control.pf-m-readonly:not(.pf-m-success,.pf-m-warning,.pf-m-error) {
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor);
}

.pf-v5-c-form-control.pf-m-readonly.pf-m-plain {
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor);
  --pf-v5-c-form-control--inset--base: var(--pf-v5-c-form-control--m-readonly--m-plain--inset--base);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  --pf-v5-c-form-control--OutlineOffset: var(--pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset);
}

.pf-v5-c-form-control:hover {
  border-bottom-color: var(--pf-v5-c-form-control--hover--after--BorderBottomColor);
}

.pf-v5-c-form-control:focus-within {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--focus--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--focus--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-expanded {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-disabled,
.pf-v5-c-form-control:disabled {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-disabled--BackgroundColor);
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-c-form-control--m-disabled__toggle-icon--Color);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  border-color: transparent;
  cursor: not-allowed;
}

.pf-v5-c-form-control.pf-m-error {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-error--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-error--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-error__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-error--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-error--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-error.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-success {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-success--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-success--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-success__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-success--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-success--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-success.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-warning {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-warning--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-warning--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-warning__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-warning--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-warning--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-warning.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--PaddingRight);
}

select.pf-v5-c-form-control {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control__select--PaddingRight);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control__select--PaddingLeft);
}

@-moz-document url-prefix()  {
  select.pf-v5-c-form-control {
    --pf-v5-c-form-control--PaddingRight: calc(var(--pf-v5-c-form-control__select--PaddingRight) - 1px);
    --pf-v5-c-form-control--PaddingLeft: calc(var(--pf-v5-c-form-control__select--PaddingLeft) - 4px);
  }
}

.pf-v5-c-form-control.pf-m-placeholder>select {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control.pf-m-placeholder>select * {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--child--Color);
}

.pf-v5-c-form-control.pf-m-placeholder>select *:disabled {
  color: revert;
}

.pf-v5-c-form-control>textarea {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  resize: none;
}

.pf-v5-c-form-control.pf-m-resize-vertical {
  --pf-v5-c-form-control--Resize: vertical;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-horizontal {
  --pf-v5-c-form-control--Resize: horizontal;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-both {
  --pf-v5-c-form-control--Resize: both;
  overflow: auto;
}

.pf-v5-c-form-control__icon {
  padding-block-start: var(--pf-v5-c-form-control__icon--PaddingTop);
  color: var(--pf-v5-c-form-control__icon--Color);
}

.pf-v5-c-form-control__icon.pf-m-status {
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-c-form-control__icon--m-status--Color);
}

.pf-v5-c-form-control__toggle-icon {
  grid-row: 1/2;
  grid-column: 3;
  padding-block-start: var(--pf-v5-c-form-control__toggle-icon--PaddingTop);
  padding-inline-start: var(--pf-v5-c-form-control__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control__toggle-icon--PaddingRight);
  color: var(--pf-v5-c-form-control__toggle-icon--Color);
  pointer-events: none;
}

.pf-v5-c-form-control__utilities {
  display: flex;
  flex-wrap: nowrap;
  grid-row: 1/2;
  grid-column: 2;
  gap: var(--pf-v5-c-form-control__utilities--Gap);
  padding-inline-end: var(--pf-v5-c-form-control__utilities--PaddingRight);
  pointer-events: none;
}

:where(.pf-theme-dark) .pf-v5-c-form-control {
  --pf-c-form-control__select--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23e0e0e0' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  --pf-c-form-control--BorderTopColor: transparent;
  --pf-c-form-control--BorderRightColor: transparent;
  --pf-c-form-control--BorderBottomColor: var(--pf-global--BorderColor--400);
  --pf-c-form-control--BorderLeftColor: transparent;
  --pf-c-form-control--BackgroundColor: var(--pf-global--BackgroundColor--400);
  --pf-c-form-control--disabled--Color: var(--pf-global--disabled-color--300);
  --pf-c-form-control--disabled--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-form-control--readonly--BackgroundColor: var(--pf-global--BackgroundColor--400);
  color: var(--pf-global--Color--100);
}

:where(.pf-theme-dark) .pf-v5-c-form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

:where(.pf-theme-dark) .pf-v5-c-form-control[readonly] {
  border-bottom-color: var(--pf-global--palette--black-700);
}

:where(.pf-theme-dark) .pf-v5-c-form-control:disabled {
  color: var(--pf-global--palette--black-100);
}

form.pf-v5-c-form {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-global--Color--200);
}

.catalog-tile-pf-title {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.co-catalog-tile .catalog-tile-pf-icon {
  font-size: unset !important;
}

.co-catalog-tile .co-catalog--logo {
  height: 100%;
  max-width: 80px;
  min-width: auto;
  object-fit: contain;
  width: 100%;
}

.co-catalog-tile .catalog-tile-pf-icon {
  padding: var(--pf-v5-global--spacer--sm);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
  display: block;
  height: 50px;
  margin-bottom: calc(-1*var(--pf-v5-global--spacer--sm));
  margin-left: calc(-1*var(--pf-v5-global--spacer--sm));
  margin-top: calc(-1*var(--pf-v5-global--spacer--sm));
  max-width: 100%;
  min-width: 50px;
  width: fit-content;
}

:where(.pf-v5-theme-dark) .co-catalog-tile .catalog-tile-pf-icon {
  margin-left: 0;
  margin-top: 0;
}

.co-catalog-tile .pf-v5-c-card__actions {
  gap: 0;
}

.co-catalog-tile .pf-v5-c-card__actions label {
  margin-bottom: 0;
}

.co-catalog-tile .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label:hover {
  --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
}

.catalog-item-header-pf-icon {
  padding: var(--pf-v5-global--spacer--sm);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
}

.co-catalog-page__overlay-header .catalog-item-header-pf-icon {
  max-width: 80px;
  width: auto;
}

.co-create-operand__form--toggle-no-border .pf-v5-c-accordion__toggle.pf-m-expanded {
  --pf-v5-c-accordion__toggle--before--BackgroundColor: transparent;
}

.co-installed-operators .pf-v5-c-table tbody>tr>* {
  vertical-align: top;
}

.co-toolbar-no-padding .pf-v5-c-toolbar__content {
  margin-left: -1rem;
  margin-right: -1rem;
  --pf-v5-c-toolbar--PaddingTop: 0;
  --pf-v5-c-toolbar--RowGap: var(--pf-v5-global--spacer--md);
}

.monaco-aria-container {
  top: -999em;
}

.pf-v5-c-about-modal-box {
  --pf-v5-c-about-modal-box__brand-image--Height: auto !important;
}

.pf-v5-c-about-modal-box__brand {
  align-items: flex-start;
  min-width: 220px;
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    background-image: url("./imgs/pfbg_992.jpg") !important;
    background-position: 400px !important;
    background-size: cover !important;
  }
}

.pf-v5-c-about-modal-box__brand {
  background-color: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-about-modal-box__brand-image,
.pf-v5-c-brand {
  max-width: 100%;
}

.pf-v5-c-about-modal-box__content {
  background-color: var(--pf-v5-global--palette--black-1000);
  padding: var(--pf-v5-global--spacer--2xl) var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__content--MarginBottom: 0;
  --pf-v5-c-about-modal-box__content--MarginLeft: 0;
  --pf-v5-c-about-modal-box__content--MarginRight: 0;
  --pf-v5-c-about-modal-box__content--MarginTop: 0;
}

.pf-v5-c-alert--top-margin {
  margin-top: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-app-launcher__group-title {
  font-family: var(--pf-v5-global--FontFamily--text);
}

.pf-v5-c-button--align-right {
  margin-left: auto !important;
}

.pf-v5-c-button:hover .pf-v5-c-button-icon--plain,
.pf-v5-c-button:focus .pf-v5-c-button-icon--plain {
  color: var(--pf-v5-c-button--m-plain--hover--Color);
}

.pf-v5-c-button-icon--plain {
  color: var(--pf-v5-c-button--m-plain--Color);
}

.pf-v5-c-button.pf-v5-c-button--no-default-values {
  --pf-v5-c-button--PaddingTop: 0;
  --pf-v5-c-button--PaddingRight: 0;
  --pf-v5-c-button--PaddingBottom: 0;
  --pf-v5-c-button--PaddingLeft: 0;
  border: 0;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  text-align: unset;
  white-space: normal;
}

.pf-v5-c-button.pf-m-inline {
  text-align: left;
}

.pf-v5-c-button.pf-m-link--align-left {
  --pf-v5-c-button--PaddingLeft: 0;
}

.pf-v5-c-button.pf-m-link--align-right {
  --pf-v5-c-button--PaddingRight: 0;
}

.pf-v5-c-check__label {
  margin-bottom: 0;
}

@media print {
  .pf-v5-c-data-list__cell {
    padding: 1px !important;
  }
}

.pf-v5-c-data-list__item--transparent-bg {
  --pf-v5-c-data-list__item--BackgroundColor: transparent;
}

.pf-v5-c-modal-box,
.pf-v5-c-switch {
  --pf-v5-global--FontSize--md: 1rem;
}

.pf-v5-c-drawer__body {
  height: 100%;
}

.pf-v5-c-page__main.default-overflow {
  z-index: calc(var(--pf-v5-c-page__header--ZIndex) + 50);
}

.pf-v5-c-page .co-page-backdrop {
  background-color: var(--pf-global--BackgroundColor--100) !important;
}

.pf-v5-c-page .pf-v5-c-brand {
  max-height: var(--pf-c-page__header-brand-link--c-brand--MaxHeight);
}

.pf-v5-c-page .pf-v5-page__main-section--flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pf-v5-c-page .pf-v5-c-app-launcher,
.pf-v5-c-page .pf-v5-c-button,
.pf-v5-c-page .pf-c-button,
.pf-v5-c-page .pf-v5-c-dropdown,
.pf-v5-c-page .pf-c-dropdown,
.pf-v5-c-page .pf-v5-c-dropdown__menu-item,
.pf-v5-c-page .pf-c-dropdown__menu-item,
.pf-v5-c-page .pf-v5-c-dropdown__toggle,
.pf-v5-c-page .pf-c-dropdown__toggle,
.pf-v5-c-page .pf-v5-c-form-control,
.pf-v5-c-page .pf-c-form-control,
.modal-dialog .pf-v5-c-app-launcher,
.modal-dialog .pf-v5-c-button,
.modal-dialog .pf-c-button,
.modal-dialog .pf-v5-c-dropdown,
.modal-dialog .pf-c-dropdown,
.modal-dialog .pf-v5-c-dropdown__menu-item,
.modal-dialog .pf-c-dropdown__menu-item,
.modal-dialog .pf-v5-c-dropdown__toggle,
.modal-dialog .pf-c-dropdown__toggle,
.modal-dialog .pf-v5-c-form-control,
.modal-dialog .pf-c-form-control {
  font-size: 14px;
  height: auto;
}

.pf-v5-c-page .pf-v5-l-stack,
.modal-dialog .pf-v5-l-stack {
  font-size: 14px;
}

.pf-v5-c-page .pf-v5-c-form-control.pf-m-success,
.pf-v5-c-page .pf-v5-c-form-control[aria-invalid=true],
.modal-dialog .pf-v5-c-form-control.pf-m-success,
.modal-dialog .pf-v5-c-form-control[aria-invalid=true] {
  --pf-v5-global--FontSize--md: 14px;
}

.pf-v5-c-masthead__content .pf-v5-c-app-launcher__toggle,
.pf-v5-c-masthead__content .pf-v5-c-dropdown__toggle,
.pf-v5-c-masthead__content .pf-v5-c-menu-toggle,
.pf-v5-c-masthead__content .pf-v5-c-notification-badge {
  font-size: 16px !important;
}

.pf-v5-c-masthead__toggle .pf-v5-c-button {
  font-size: var(--pf-v5-c-masthead__toggle--c-button--FontSize);
}

:where(:root:not(.pf-v5-theme-dark)) .pf-v5-c-page {
  --pf-v5-c-page__sidebar--BoxShadow: none;
}

@media print {
  .pf-v5-c-page__sidebar {
    display: none !important;
  }
}

.pf-v5-c-page__sidebar.pf-m-dark .pf-v5-c-dropdown {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
}

.pf-v5-c-page__sidebar .pf-v5-c-nav {
  --pf-v5-c-nav__list-link--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__list-link--PaddinLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__list-link--after--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--FontSize: 16px;
  --pf-v5-c-nav__subnav__link--FontSize: 14px;
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-page__sidebar .pf-v5-c-nav {
    --pf-v5-c-nav__list-link--PaddingRight: var(--pf-v5-global--spacer--md);
    --pf-v5-c-nav__list-link--PaddinLeft: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-nav__list-link--after--Left: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-nav__simple-list--nested__separator--MarginLeft: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-nav__simple-list-link--nested--PaddingLeft: var(--pf-v5-global--spacer--xl);
  }
}

.pf-v5-c-page__sidebar .pf-v5-c-nav .pf-v5-c-nav__link {
  font-size: 16px;
}

.pf-v5-c-page__sidebar .pf-v5-c-nav .pf-v5-c-nav__subnav .pf-v5-c-nav__link {
  font-size: 14px;
}

.pf-v5-c-table tr>* {
  --pf-v5-c-table--cell--FontSize: $font-size-base;
}

.pf-v5-theme-dark .pf-v5-c-card {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--md);
}

.table {
  margin-bottom: 0;
}

.table--layout-fixed {
  table-layout: fixed;
}

.table td {
  vertical-align: middle !important;
}

.table th {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  padding-top: 0 !important;
}

.table thead>tr>th {
  border-bottom: 0;
}

.table tr:last-child {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.xterm .xterm-screen canvas {
  border-right: 1px solid transparent;
}

button.pf-v5-c-dropdown__menu-item.pf-m-disabled {
  pointer-events: auto;
}

#modal-container .pf-v5-c-backdrop {
  position: absolute !important;
}

.form-group--doubled-bottom-margin {
  margin-bottom: 30px;
}

ul {
  list-style: disc;
}

:is(ul,ol):where([class^="pf-"]:not(.pf-v5-c-list, .pf-c-list)) {
  list-style: none !important;
}

.pfext-quick-start-content {
  font-size: 14px !important;
}

.odc-topology .pf-topology-content {
  background-color: var(--pf-v5-global--BackgroundColor--200);
}

.pfext-quick-start-footer {
  background-color: var(--pf-v5-global--BackgroundColor--100);
}

.pf-v5-c-skip-to-content {
  position: absolute !important;
}

.properties-side-panel-pf-property-value .pf-v5-c-alert__title,
.properties-side-panel-pf-property-value .pf-v5-c-select__toggle {
  font-size: 14px;
}

:where(.pf-theme-dark) .pfext-quick-start-task__content .pfext-markdown-view pre code {
  color: var(--pf-global--Color--100);
  background-color: var(--pf-global--palette--black-600);
}

.pf-v5-c-popover {
  min-width: var(--pf-v5-c-popover--MinWidth) !important;
}

.pf-v5-c-truncate--no-min-width {
  --pf-v5-c-truncate--MinWidth: 0 !important;
}

.co-breadcrumb {
  padding-bottom: var(--pf-v5-global--spacer--sm);
}

.co-divider {
  margin-bottom: var(--pf-v5-global--spacer--lg);
  margin-top: var(--pf-v5-global--spacer--lg);
}

dl.co-inline dd {
  margin-bottom: 1em;
}

dl.co-inline dt {
  float: left;
  margin-right: .4em;
}

.co-m-pane__body {
  margin: 1.5rem 0 0;
  padding: 0 1rem 1.5rem;
}

@media (min-width: 1200px) {
  .co-m-pane__body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-m-pane__body--full-height {
  height: 100%;
}

.co-m-pane__body--no-top-margin {
  margin-top: 0;
}

.co-m-pane__body--section-heading {
  padding-bottom: 0;
}

.co-m-pane__body+.co-m-pane__body {
  border-top: 1px solid var(--pf-v5-global--BorderColor--100);
  margin-top: 0;
  padding-top: 1.5rem;
}

.co-m-pane__createLink--no-title {
  margin-bottom: 1.5rem;
}

.co-m-pane__filter-bar,
.co-m-pane__help-text {
  margin: 20px 1rem;
}

@media (min-width: 768px) {
  .co-m-pane__filter-bar,
  .co-m-pane__help-text {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
  }
}

.co-button-help-icon {
  padding: 0 5px;
}

.co-m-pane__filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.co-m-pane__filter-bar .co-m-primary-action,
.co-m-pane__filter-bar .pf-v5-c-form-control {
  margin-bottom: 10px;
}

.co-m-pane__filter-bar-group {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: 0;
}

@media (min-width: 480px) {
  .co-m-pane__filter-bar-group--filter {
    flex: 1 0 auto;
    justify-content: flex-end;
  }
}

.co-m-pane__filter-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.co-m-pane__filter-row-action {
  padding-top: 1rem;
}

@media (min-width: 480px) {
  .co-m-pane__filter-row-action {
    padding-top: 0;
  }
}

.co-m-pane__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem !important;
}

.co-m-pane__heading--baseline {
  align-items: baseline;
}

.co-m-pane__heading--center {
  justify-content: center;
}

.co-m-pane__heading--logo {
  align-items: center;
}

.co-m-pane__heading--with-help-text {
  margin-bottom: 0 !important;
}

@media (min-width: 768px) {
  .co-m-pane__heading-badge {
    margin-left: var(--pf-v5-global--spacer--sm);
  }
}

.co-m-pane__heading-help-text {
  margin-bottom: 1.5rem !important;
}

.co-m-pane__heading-owner {
  display: block;
  font-size: 14px;
  line-height: var(--pf-v5-global--LineHeight--md);
  padding-top: var(--pf-v5-global--spacer--sm);
}

.co-m-pane__heading-owner span {
  display: inline;
}

.co-m-pane__heading-link {
  font-size: 14px;
}

.co-no-bold {
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

.cos-error-title {
  color: #c9190b;
}

.co-section-heading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.co-section-heading-secondary {
  font-size: 16px;
  margin: 30px 0;
}

.co-section-heading-tertiary {
  font-size: 16px;
  margin: 15px 0;
}

.co-switch-label {
  margin-bottom: 0;
}

.co-switch-label .pf-v5-c-switch__label {
  font-size: 14px;
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

.co-table-container {
  overflow: auto;
  margin-bottom: 30px;
}

.co-m-pane__details {
  line-height: 1.66;
  min-width: 0;
  white-space: normal;
}

.co-detail-table__row .co-m-pane__details {
  margin-bottom: 0;
}

.co-m-pane__details dd {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.co-m-pane__dropdown {
  margin-bottom: 20px;
}

.co-m-pane__explanation {
  color: #6a6e73;
  margin-bottom: 30px;
}

.co-m-pane__explanation--alt {
  margin-bottom: 20px;
}

.co-help-text {
  color: var(--pf-v5-global--Color--200);
}

.co-m-pane__explanation code {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  color: #6a6e73;
}

.co-pre-line {
  white-space: pre-line;
}

.co-pre-wrap {
  white-space: pre-wrap;
}

@supports (-webkit-overflow-scrolling: touch) {
@media (max-width: 575px) {
    input,
    select,
    .tag-item,
    textarea {
      font-size: 16px !important;
    }
}
}

.co-error {
  color: #c9190b;
  white-space: nowrap;
}

.error-message {
  color: var(--pf-v5-global--palette--white);
  padding: 2px 12px;
  background-color: #d64456;
}

.co-break-word {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.co-break-all {
  word-break: break-all;
}

.co-external-link::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-size: 75%;
  height: 16px;
  margin-left: 3px;
  margin-right: -15px;
  position: relative;
  top: 0;
  width: 12px;
}

.pf-v5-c-dropdown__menu-item .co-external-link::after {
  color: #6a6e73;
  margin-left: 5px;
}

.pf-v5-c-nav__link .co-external-link::after {
  color: #8a8d90;
  margin-left: 4px;
}

.co-external-link {
  display: inline-block;
  padding-right: 15px;
}

.co-external-link--block {
  display: block;
}

.co-external-link-with-copy__icon {
  color: var(--pf-v5-global--link--Color);
  cursor: pointer;
}

.co-external-link-with-copy__icon:hover {
  color: var(--pf-v5-global--Color--300);
}

.co-external-link-with-copy__externallinkicon {
  padding-left: var(--pf-v5-global--spacer--xs);
}

.co-external-link-with-copy__copyicon {
  margin-left: var(--pf-v5-global--spacer--md);
}

.co-icon-nowrap {
  display: inline;
  white-space: nowrap;
}

.co-goto-arrow::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-size: 75%;
  height: 16px;
  margin-left: 3px;
  margin-right: -15px;
  position: relative;
  top: 0;
  width: 12px;
}

.co-icon-flex-child {
  flex: 0 0 auto;
  position: relative;
  top: .125em;
}

.co-line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.co-sidebar-dismiss {
  padding: 0 5px;
}

.co-text-list-plain {
  list-style: none !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.co-toolbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}

.co-toolbar__item {
  max-width: 100%;
  padding: 5px 0;
}

.co-toolbar__item .co-toolbar__item-dropdown {
  max-width: 100%;
}

.co-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 0;
}

@media (max-width: 767px) {
  .co-toolbar__group {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.co-toolbar__group--left {
  justify-content: flex-start;
}

.co-toolbar__group--left .co-toolbar__item {
  padding-right: 15px;
}

@media (max-width: 767px) {
  .co-toolbar__group--left {
    align-items: flex-start;
  }
}

.co-toolbar__group--alongside-kebab {
  flex: 1 1 0;
  flex-direction: row;
}

.co-toolbar__group--kebab {
  justify-content: flex-start;
}

.co-toolbar__group--right {
  justify-content: flex-end;
}

@media (min-width: 480px) {
  .co-toolbar__group--right .co-toolbar__item {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .co-toolbar__group--right {
    align-items: flex-end;
  }
}

.co-legend {
  border-bottom: 0;
  font-weight: var(--pf-v5-global--FontWeight--bold);
  font-size: 14px;
  margin-bottom: 5px;
}

.co-legend--nested {
  margin-top: 10px;
}

.co-legend+.radio {
  margin-top: 0;
}

.help-block {
  margin-bottom: 0;
}

.co-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.co-select-to-copy {
  cursor: copy;
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
}

.co-nowrap {
  white-space: nowrap;
}

.co-an-fade-in-out {
  transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
}

.co-m-radio-desc {
  margin-left: 20px;
}

.co-m-form-row {
  margin-bottom: 20px;
}

.co-m-form-row:last-child {
  margin-bottom: 0;
}

.co-m-form-col {
  max-width: 600px;
}

.co-modal-btn-link {
  outline: none;
  padding: 0 20px 0 0;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
}

.co-modal-btn-link::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #6a6e73;
  content: "";
  line-height: 1;
  margin-left: 5px;
  margin-right: -20px;
  pointer-events: none;
  position: relative;
  right: 0;
  width: 15px;
}

.co-modal-btn-link:hover::after {
  color: #4f5255;
}

.co-modal-btn-link--inline {
  margin: 0 8px;
}

.co-modal-btn-link--left {
  text-align: left;
}

.co-m-pane__body-group {
  padding: 0 0 30px 0;
}

.co-m-table-grid__body {
  min-height: 50px;
  position: relative;
  width: 100%;
}

.co-m-table-grid__body .row {
  padding: 10px 20px 10px 0;
}

.co-m-table-grid__head {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  padding: 0 20px 10px 0;
}

.co-m-table-grid__head a {
  cursor: pointer;
}

.co-m-table-grid--bordered .co-m-table-grid__body .row,
.co-m-table-grid--bordered .co-m-table-grid__head {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}

.co-m-table-grid .co-m-table-grid {
  padding-left: 20px;
}

.co-m-table-grid .row {
  line-height: normal;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.co-m-loader {
  min-width: 18px;
}

.co-m-loader-dot__one,
.co-m-loader-dot__two,
.co-m-loader-dot__three {
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: bouncedelay;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  background: #419eda;
  border-radius: 100%;
  display: inline-block;
  height: 6px;
  width: 6px;
}

.co-m-loader-dot__one {
  animation-delay: -0.32s;
}

.co-m-loader-dot__two {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.25, 0.25);
  }

  40% {
    transform: scale(1, 1);
  }
}

.no-margin {
  margin: 0 !important;
}

.co-overview-card--gradient {
  position: relative;
}

.co-overview-card--gradient:after {
  background: linear-gradient(rgba(255, 255, 255, 0), var(--pf-v5-global--BackgroundColor--100));
  bottom: 0;
  content: "";
  left: 0;
  min-height: 2rem;
  pointer-events: none;
  position: absolute;
  width: calc(100% - var(--pf-v5-global--spacer--lg));
}

.co-overview-card__actions {
  align-self: center !important;
}

.co-icon-space-l {
  margin-left: .35em;
}

.co-icon-space-r {
  margin-right: .25em;
}

html,
body,
#app,
.pf-v5-c-drawer {
  height: 100%;
}

#app,
#content {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
}

#content-scrollable {
  overflow: auto;
  height: 100%;
}

.co-m-page__body {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.co-m-app__content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
}

.co-m-app__content>.pf-v5-c-page {
  height: 0;
  flex: 1;
}

.co-p-has-sidebar {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
}

.co-p-has-sidebar__body {
  flex: 2;
}

.co-p-has-sidebar__close-button {
  font-size: 21px !important;
  line-height: 1 !important;
  padding: 0 !important;
}

.co-p-has-sidebar__sidebar {
  position: relative;
}

@media (min-width: 992px) {
  .co-p-has-sidebar__sidebar {
    flex: 1 0 auto;
    overflow-y: auto;
  }
}

@media (max-width: 991px) {
  .co-p-has-sidebar__sidebar--bordered {
    border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  }
}

@media (min-width: 992px) {
  .co-p-has-sidebar__sidebar--bordered {
    border-left: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  }
}

.co-p-has-sidebar__sidebar-body {
  position: absolute;
  width: 100%;
}

.co-p-has-sidebar__sidebar-body:focus {
  outline: 0;
}

.co-p-has-sidebar__sidebar-heading {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-bottom: 20px;
  margin-top: 0;
}

.pf-v5-c-label {
  --pf-v5-c-label--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--MaxWidth: 100%;
  --pf-v5-c-label--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-label--m-outline__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-blue--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-label--m-blue__icon--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-blue__content--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-label--m-blue__content--before--BorderColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-label--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-green--BackgroundColor: var(--pf-v5-global--palette--green-50);
  --pf-v5-c-label--m-green__icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-green__content--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-label--m-green__content--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-orange--BackgroundColor: var(--pf-v5-global--palette--orange-50);
  --pf-v5-c-label--m-orange__icon--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-orange__content--Color: var(--pf-v5-global--palette--orange-700);
  --pf-v5-c-label--m-orange__content--before--BorderColor: var(--pf-v5-global--palette--orange-100);
  --pf-v5-c-label--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--Color: var(--pf-v5-global--palette--orange-500);
  --pf-v5-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-red--BackgroundColor: var(--pf-v5-global--palette--red-50);
  --pf-v5-c-label--m-red__icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-red__content--Color: var(--pf-v5-global--palette--red-300);
  --pf-v5-c-label--m-red__content--before--BorderColor: var(--pf-v5-global--palette--red-100);
  --pf-v5-c-label--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-purple--BackgroundColor: var(--pf-v5-global--palette--purple-50);
  --pf-v5-c-label--m-purple__icon--Color: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-purple__content--Color: var(--pf-v5-global--palette--purple-700);
  --pf-v5-c-label--m-purple__content--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-outline--m-purple__content--Color: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-cyan--BackgroundColor: var(--pf-v5-global--palette--cyan-50);
  --pf-v5-c-label--m-cyan__icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-cyan__content--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-label--m-cyan__content--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--Color: var(--pf-v5-global--palette--cyan-400);
  --pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-gold--BackgroundColor: var(--pf-v5-global--palette--gold-50);
  --pf-v5-c-label--m-gold__icon--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-label--m-gold__content--Color: var(--pf-v5-global--palette--gold-700);
  --pf-v5-c-label--m-gold__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--Color: var(--pf-v5-global--palette--gold-600);
  --pf-v5-c-label--m-outline--m-gold__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-overflow__content--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-label--m-overflow__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-label--m-overflow__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-overflow__content--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-overflow__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-overflow__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-overflow__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-overflow__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-compact--PaddingTop: 0;
  --pf-v5-c-label--m-compact--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-compact--PaddingBottom: 0;
  --pf-v5-c-label--m-compact--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-compact--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label--m-compact--m-editable--TextDecorationOffset: 0.0625rem;
  --pf-v5-c-label__content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label__content--BackgroundColor: transparent;
  --pf-v5-c-label__content--MaxWidth: 100%;
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable__content--MaxWidth: 16ch;
  --pf-v5-c-label__text--MaxWidth: 100%;
  --pf-v5-c-label__icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label__icon--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label__actions--MarginRight: calc(var(--pf-v5-c-label__actions--c-button--PaddingRight) * -1);
  --pf-v5-c-label__actions--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label__actions--c-button--MarginTop: calc(var(--pf-v5-c-label__actions--c-button--PaddingTop) * -1);
  --pf-v5-c-label__actions--c-button--MarginBottom: calc(var(--pf-v5-c-label__actions--c-button--PaddingBottom) * -1);
  --pf-v5-c-label__actions--c-button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label__actions--c-button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-editable--Cursor: pointer;
  --pf-v5-c-label--m-editable--TextDecoration: underline;
  --pf-v5-c-label--m-editable--TextDecorationStyle: dashed;
  --pf-v5-c-label--m-editable--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable--TextDecorationOffset: 0.25rem;
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label--m-editable--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable__content--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable__content--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable--m-editable-active--Cursor: auto;
  --pf-v5-c-label--m-editable--m-editable-active--TextDecoration: none;
  --pf-v5-c-label--m-editable--m-editable-active--BackgroundColor: transparent;
  --pf-v5-c-label--m-editable--m-editable-active__content--before--BorderWidth: 0;
  --pf-v5-c-label--m-editable--m-editable-active__content--before--BorderColor: transparent;
  position: relative;
  max-width: var(--pf-v5-c-label--MaxWidth);
  padding-block-start: var(--pf-v5-c-label--PaddingTop);
  padding-block-end: var(--pf-v5-c-label--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-label--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-label--PaddingRight);
  font-size: var(--pf-v5-c-label--FontSize);
  color: var(--pf-v5-c-label--Color);
  white-space: nowrap;
  background-color: var(--pf-v5-c-label--BackgroundColor);
  border: 0;
  border-radius: var(--pf-v5-c-label--BorderRadius);
}

.pf-v5-c-label.pf-m-compact {
  --pf-v5-c-label--PaddingTop: var(--pf-v5-c-label--m-compact--PaddingTop);
  --pf-v5-c-label--PaddingRight: var(--pf-v5-c-label--m-compact--PaddingRight);
  --pf-v5-c-label--PaddingBottom: var(--pf-v5-c-label--m-compact--PaddingBottom);
  --pf-v5-c-label--PaddingLeft: var(--pf-v5-c-label--m-compact--PaddingLeft);
  --pf-v5-c-label--FontSize: var(--pf-v5-c-label--m-compact--FontSize);
  --pf-v5-c-label--m-editable--TextDecorationOffset: var(--pf-v5-c-label--m-compact--m-editable--TextDecorationOffset);
}

.pf-v5-c-label.pf-m-blue,
.pf-v5-c-label.co-label.co-m-silence,
.pf-v5-c-label.co-label.co-m-replicationcontroller,
.pf-v5-c-label.co-label.co-m-replicaset,
.pf-v5-c-label.co-label.co-m-metricstarget,
.pf-v5-c-label.co-label.co-m-job,
.pf-v5-c-label.co-label.co-m-deploymentconfig,
.pf-v5-c-label.co-label.co-m-deployment,
.pf-v5-c-label.co-label.co-m-daemonset,
.pf-v5-c-label.co-label.co-m-clusterserviceversion,
.pf-v5-c-label.co-label.co-m-alert {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-blue--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-blue__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-blue__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-blue__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-blue__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-blue__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-green,
.pf-v5-c-label.co-label.co-m-servicemonitor,
.pf-v5-c-label.co-label.co-m-service,
.pf-v5-c-label.co-label.co-m-project,
.pf-v5-c-label.co-label.co-m-namespace {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-green--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-green__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-green__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-green__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-green__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-green__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-orange,
.pf-v5-c-label.co-label.co-m-secret,
.pf-v5-c-label.co-label.co-m-alertmanager {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-orange--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-orange__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-orange__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-orange__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-orange__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-orange__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-red {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-red--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-red__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-red__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-red__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-red__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-red__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-purple,
.pf-v5-c-label.co-label.co-m-serviceaccount,
.pf-v5-c-label.co-label.co-m-node,
.pf-v5-c-label.co-label.co-m-machineset,
.pf-v5-c-label.co-label.co-m-machinedeployment,
.pf-v5-c-label.co-label.co-m-machineconfigpool,
.pf-v5-c-label.co-label.co-m-machineconfig,
.pf-v5-c-label.co-label.co-m-machineautoscaler,
.pf-v5-c-label.co-label.co-m-machine,
.pf-v5-c-label.co-label.co-m-ingress,
.pf-v5-c-label.co-label.co-m-configmap,
.pf-v5-c-label.co-label.co-m-alertrule {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-purple--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-purple__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-purple__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-purple__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-purple__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-purple__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-cyan,
.pf-v5-c-label.co-label.co-m-pod {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-cyan--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-cyan__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-cyan__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-cyan__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-gold {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-gold--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-gold__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-gold__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-gold__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-gold__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-gold__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-outline {
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-outline__content--Color);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-outline--BackgroundColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-overflow:hover,
.pf-v5-c-label.pf-m-add:hover,
.pf-v5-c-label.pf-m-outline a.pf-v5-c-label__content:hover,
.pf-v5-c-label.pf-m-outline button.pf-v5-c-label__content:hover {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--link--hover--before--BorderColor);
}

.pf-v5-c-label.pf-m-overflow:focus,
.pf-v5-c-label.pf-m-add:focus,
.pf-v5-c-label.pf-m-outline a.pf-v5-c-label__content:focus,
.pf-v5-c-label.pf-m-outline button.pf-v5-c-label__content:focus {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--link--focus--before--BorderColor);
}

.pf-v5-c-label.pf-m-editable {
  --pf-v5-c-label__content--MaxWidth: var(--pf-v5-c-label--m-editable__content--MaxWidth);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-editable__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--hover--before--BorderWidth);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-editable__content--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--focus--before--BorderWidth);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-editable__content--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  text-decoration: var(--pf-v5-c-label--m-editable--TextDecoration);
  text-decoration-thickness: var(--pf-v5-c-label--m-editable--TextDecorationThickness);
  text-decoration-style: var(--pf-v5-c-label--m-editable--TextDecorationStyle);
  text-decoration-color: var(--pf-v5-c-label--m-editable--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-label--m-editable--TextDecorationOffset);
  cursor: var(--pf-v5-c-label--m-editable--Cursor);
}

.pf-v5-c-label.pf-m-editable:hover {
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-c-label--m-editable--hover--TextDecorationColor);
}

.pf-v5-c-label.pf-m-editable:focus {
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-c-label--m-editable--focus--TextDecorationColor);
}

.pf-v5-c-label.pf-m-editable-active {
  --pf-v5-c-label--m-editable--Cursor: var(--pf-v5-c-label--m-editable--m-editable-active--Cursor);
  --pf-v5-c-label--m-editable--TextDecoration: var(--pf-v5-c-label--m-editable--m-editable-active--TextDecoration);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-editable--m-editable-active--BackgroundColor);
}

.pf-v5-c-label.pf-m-overflow,
.pf-v5-c-label.pf-m-add {
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-overflow__content--Color);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-overflow__content--BackgroundColor);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--link--focus--before--BorderColor);
}

.pf-v5-c-label,
.pf-v5-c-label__content {
  display: inline-flex;
  align-items: center;
}

.pf-v5-c-label__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-label__text--MaxWidth);
}

.pf-v5-c-label__content {
  max-width: var(--pf-v5-c-label__content--MaxWidth);
  overflow: hidden;
  color: var(--pf-v5-c-label__content--Color);
  background-color: var(--pf-v5-c-label__content--BackgroundColor);
  border-width: 0;
}

.pf-v5-c-label__content::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-label__content--before--BorderWidth) solid var(--pf-v5-c-label__content--before--BorderColor);
  border-radius: var(--pf-v5-c-label--BorderRadius);
}

input.pf-v5-c-label__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.pf-v5-c-label__content,
button.pf-v5-c-label__content {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

a.pf-v5-c-label__content,
a.pf-v5-c-label__content:hover,
a.pf-v5-c-label__content:focus,
button.pf-v5-c-label__content,
button.pf-v5-c-label__content:hover,
button.pf-v5-c-label__content:focus {
  text-decoration: none;
}

a.pf-v5-c-label__content:hover,
button.pf-v5-c-label__content:hover {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label__content--link--hover--before--BorderColor);
}

a.pf-v5-c-label__content:focus,
button.pf-v5-c-label__content:focus {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label__content--link--focus--before--BorderColor);
}

.pf-v5-c-label__icon {
  margin-inline-end: var(--pf-v5-c-label__icon--MarginRight);
  color: var(--pf-v5-c-label__icon--Color);
}

.pf-v5-c-label__actions {
  margin-inline-end: var(--pf-v5-c-label__actions--MarginRight);
  font-size: var(--pf-v5-c-label__actions--FontSize);
}

.pf-v5-c-label__actions .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-label__actions--c-button--FontSize);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-label__actions--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-label__actions--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-label__actions--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-label__actions--c-button--PaddingLeft);
  margin-block-start: var(--pf-v5-c-label__actions--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-label__actions--c-button--MarginBottom);
}

:where(.pf-v5-theme-dark) .pf-v5-c-label {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-blue--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-green--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-orange--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-red--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-purple--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-cyan--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-gold--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label__content--before--BorderWidth: 0;
  --pf-v5-c-label__content--before--BorderColor: transparent;
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-blue__content--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-green__content--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-orange__content--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-red__content--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-purple__content--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__icon--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-cyan__content--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-gold__content--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-100);
}

.co-text-alert:where(:not([class*="co-m-"])) {
  color: #2b9af3;
}

.co-text-alert:where(:not([class*="co-m-"])):link,
.co-text-alert:where(:not([class*="co-m-"])):visited,
.co-text-alert:where(:not([class*="co-m-"])):hover,
.co-text-alert:where(:not([class*="co-m-"])):active {
  color: #2b9af3;
  text-decoration: none;
}

.co-text-alertmanager:where(:not([class*="co-m-"])) {
  color: #773d00;
}

.co-text-alertmanager:where(:not([class*="co-m-"])):link,
.co-text-alertmanager:where(:not([class*="co-m-"])):visited,
.co-text-alertmanager:where(:not([class*="co-m-"])):hover,
.co-text-alertmanager:where(:not([class*="co-m-"])):active {
  color: #773d00;
  text-decoration: none;
}

.co-text-alertrule:where(:not([class*="co-m-"])) {
  color: #40199a;
}

.co-text-alertrule:where(:not([class*="co-m-"])):link,
.co-text-alertrule:where(:not([class*="co-m-"])):visited,
.co-text-alertrule:where(:not([class*="co-m-"])):hover,
.co-text-alertrule:where(:not([class*="co-m-"])):active {
  color: #40199a;
  text-decoration: none;
}

.co-text-configmap:where(:not([class*="co-m-"])) {
  color: #40199a;
}

.co-text-configmap:where(:not([class*="co-m-"])):link,
.co-text-configmap:where(:not([class*="co-m-"])):visited,
.co-text-configmap:where(:not([class*="co-m-"])):hover,
.co-text-configmap:where(:not([class*="co-m-"])):active {
  color: #40199a;
  text-decoration: none;
}

.co-text-daemonset:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-daemonset:where(:not([class*="co-m-"])):link,
.co-text-daemonset:where(:not([class*="co-m-"])):visited,
.co-text-daemonset:where(:not([class*="co-m-"])):hover,
.co-text-daemonset:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-deployment:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-deployment:where(:not([class*="co-m-"])):link,
.co-text-deployment:where(:not([class*="co-m-"])):visited,
.co-text-deployment:where(:not([class*="co-m-"])):hover,
.co-text-deployment:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-deploymentconfig:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-deploymentconfig:where(:not([class*="co-m-"])):link,
.co-text-deploymentconfig:where(:not([class*="co-m-"])):visited,
.co-text-deploymentconfig:where(:not([class*="co-m-"])):hover,
.co-text-deploymentconfig:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-ingress:where(:not([class*="co-m-"])) {
  color: #40199a;
}

.co-text-ingress:where(:not([class*="co-m-"])):link,
.co-text-ingress:where(:not([class*="co-m-"])):visited,
.co-text-ingress:where(:not([class*="co-m-"])):hover,
.co-text-ingress:where(:not([class*="co-m-"])):active {
  color: #40199a;
  text-decoration: none;
}

.co-text-job:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-job:where(:not([class*="co-m-"])):link,
.co-text-job:where(:not([class*="co-m-"])):visited,
.co-text-job:where(:not([class*="co-m-"])):hover,
.co-text-job:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-machine:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machine:where(:not([class*="co-m-"])):link,
.co-text-machine:where(:not([class*="co-m-"])):visited,
.co-text-machine:where(:not([class*="co-m-"])):hover,
.co-text-machine:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-machineautoscaler:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machineautoscaler:where(:not([class*="co-m-"])):link,
.co-text-machineautoscaler:where(:not([class*="co-m-"])):visited,
.co-text-machineautoscaler:where(:not([class*="co-m-"])):hover,
.co-text-machineautoscaler:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-machineconfig:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machineconfig:where(:not([class*="co-m-"])):link,
.co-text-machineconfig:where(:not([class*="co-m-"])):visited,
.co-text-machineconfig:where(:not([class*="co-m-"])):hover,
.co-text-machineconfig:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-machineconfigpool:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machineconfigpool:where(:not([class*="co-m-"])):link,
.co-text-machineconfigpool:where(:not([class*="co-m-"])):visited,
.co-text-machineconfigpool:where(:not([class*="co-m-"])):hover,
.co-text-machineconfigpool:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-machinedeployment:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machinedeployment:where(:not([class*="co-m-"])):link,
.co-text-machinedeployment:where(:not([class*="co-m-"])):visited,
.co-text-machinedeployment:where(:not([class*="co-m-"])):hover,
.co-text-machinedeployment:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-machineset:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-machineset:where(:not([class*="co-m-"])):link,
.co-text-machineset:where(:not([class*="co-m-"])):visited,
.co-text-machineset:where(:not([class*="co-m-"])):hover,
.co-text-machineset:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-metricstarget:where(:not([class*="co-m-"])) {
  color: #2b9af3;
}

.co-text-metricstarget:where(:not([class*="co-m-"])):link,
.co-text-metricstarget:where(:not([class*="co-m-"])):visited,
.co-text-metricstarget:where(:not([class*="co-m-"])):hover,
.co-text-metricstarget:where(:not([class*="co-m-"])):active {
  color: #2b9af3;
  text-decoration: none;
}

.co-text-namespace:where(:not([class*="co-m-"])) {
  color: #1e4f18;
}

.co-text-namespace:where(:not([class*="co-m-"])):link,
.co-text-namespace:where(:not([class*="co-m-"])):visited,
.co-text-namespace:where(:not([class*="co-m-"])):hover,
.co-text-namespace:where(:not([class*="co-m-"])):active {
  color: #1e4f18;
  text-decoration: none;
}

.co-text-node:where(:not([class*="co-m-"])) {
  color: #8476d1;
}

.co-text-node:where(:not([class*="co-m-"])):link,
.co-text-node:where(:not([class*="co-m-"])):visited,
.co-text-node:where(:not([class*="co-m-"])):hover,
.co-text-node:where(:not([class*="co-m-"])):active {
  color: #8476d1;
  text-decoration: none;
}

.co-text-pod:where(:not([class*="co-m-"])) {
  color: #009596;
}

.co-text-pod:where(:not([class*="co-m-"])):link,
.co-text-pod:where(:not([class*="co-m-"])):visited,
.co-text-pod:where(:not([class*="co-m-"])):hover,
.co-text-pod:where(:not([class*="co-m-"])):active {
  color: #009596;
  text-decoration: none;
}

.co-text-project:where(:not([class*="co-m-"])) {
  color: #1e4f18;
}

.co-text-project:where(:not([class*="co-m-"])):link,
.co-text-project:where(:not([class*="co-m-"])):visited,
.co-text-project:where(:not([class*="co-m-"])):hover,
.co-text-project:where(:not([class*="co-m-"])):active {
  color: #1e4f18;
  text-decoration: none;
}

.co-text-replicaset:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-replicaset:where(:not([class*="co-m-"])):link,
.co-text-replicaset:where(:not([class*="co-m-"])):visited,
.co-text-replicaset:where(:not([class*="co-m-"])):hover,
.co-text-replicaset:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-replicationcontroller:where(:not([class*="co-m-"])) {
  color: #004080;
}

.co-text-replicationcontroller:where(:not([class*="co-m-"])):link,
.co-text-replicationcontroller:where(:not([class*="co-m-"])):visited,
.co-text-replicationcontroller:where(:not([class*="co-m-"])):hover,
.co-text-replicationcontroller:where(:not([class*="co-m-"])):active {
  color: #004080;
  text-decoration: none;
}

.co-text-secret:where(:not([class*="co-m-"])) {
  color: #c46100;
}

.co-text-secret:where(:not([class*="co-m-"])):link,
.co-text-secret:where(:not([class*="co-m-"])):visited,
.co-text-secret:where(:not([class*="co-m-"])):hover,
.co-text-secret:where(:not([class*="co-m-"])):active {
  color: #c46100;
  text-decoration: none;
}

.co-text-service:where(:not([class*="co-m-"])) {
  color: #6ca100;
}

.co-text-service:where(:not([class*="co-m-"])):link,
.co-text-service:where(:not([class*="co-m-"])):visited,
.co-text-service:where(:not([class*="co-m-"])):hover,
.co-text-service:where(:not([class*="co-m-"])):active {
  color: #6ca100;
  text-decoration: none;
}

.co-text-serviceaccount:where(:not([class*="co-m-"])) {
  color: #40199a;
}

.co-text-serviceaccount:where(:not([class*="co-m-"])):link,
.co-text-serviceaccount:where(:not([class*="co-m-"])):visited,
.co-text-serviceaccount:where(:not([class*="co-m-"])):hover,
.co-text-serviceaccount:where(:not([class*="co-m-"])):active {
  color: #40199a;
  text-decoration: none;
}

.co-text-servicemonitor:where(:not([class*="co-m-"])) {
  color: #6ca100;
}

.co-text-servicemonitor:where(:not([class*="co-m-"])):link,
.co-text-servicemonitor:where(:not([class*="co-m-"])):visited,
.co-text-servicemonitor:where(:not([class*="co-m-"])):hover,
.co-text-servicemonitor:where(:not([class*="co-m-"])):active {
  color: #6ca100;
  text-decoration: none;
}

.co-text-silence:where(:not([class*="co-m-"])) {
  color: #2b9af3;
}

.co-text-silence:where(:not([class*="co-m-"])):link,
.co-text-silence:where(:not([class*="co-m-"])):visited,
.co-text-silence:where(:not([class*="co-m-"])):hover,
.co-text-silence:where(:not([class*="co-m-"])):active {
  color: #2b9af3;
  text-decoration: none;
}

.co-add-remove-form__entry {
  position: relative;
}

.co-add-remove-form__entry+.co-add-remove-form__entry {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding-top: 10px;
}

.co-add-remove-form__link--remove-entry {
  display: flex;
  flex-flow: row-reverse;
}

.co-required>* {
  display: inline-block;
}

.co-required:after {
  color: var(--pf-v5-global--danger-color--100);
  content: "*";
  font-size: 14px;
  padding-left: 3px;
  vertical-align: top;
}

.co-form-section__label {
  font-size: 14px;
  margin-bottom: 1rem;
}

.co-form-section__separator {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  margin: 30px 0;
}

.co-form-subsection {
  margin-left: 20px;
}

.co-kbd {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  border-radius: 3px;
  color: var(--pf-v5-global--Color--200);
  font-size: var(--pf-v5-global--FontSize--sm);
  padding: 1px 3px;
}

.co-kbd__filter-input {
  border-color: var(--pf-v5-global--BorderColor--300);
}

.co-label--plain {
  font-weight: var(--pf-v5-global--FontWeight--normal);
  margin-bottom: 0px;
  display: inline;
}

@media (min-width: 769px) {
  .co-m-pane__form {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  .co-m-pane__form {
    min-width: 650px;
  }
}

@media (min-width: 1200px) {
  .co-m-pane__form {
    max-width: 50%;
  }
}

.co-text-filter:focus+.co-text-filter-feedback {
  display: none;
}

.co-text-filter-feedback {
  display: block;
  height: 29px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 5px;
  width: 29px;
  z-index: 2;
}

.has-feedback .pf-v5-c-form-control {
  --pf-v5-c-form-control--PaddingRight: 25px;
}

.pf-v5-c-form__actions--right {
  justify-content: flex-end;
}

.pf-v5-c-form__checkbox-row {
  display: grid;
  row-gap: 1rem;
}

.pf-v5-c-form__group--no-top-margin {
  margin-top: 0 !important;
}

@media print {
  * {
    overflow: visible !important;
  }

  .co-m-resource-icon {
    border: 1px solid gray;
  }

  .odc-topology-list-view__item-row {
    padding: 8px 0 2px;
  }

  .odc-namespaced-page,
  .odc-namespaced-page__content {
    height: 100%;
  }

  .odc-topology-list-view__kind-row {
    border-width: 1px !important;
  }
}

.co-alert {
  margin-bottom: var(--pf-v5-global--spacer--lg);
  text-align: left;
}

.co-alert h4 {
  font-family: var(--pf-v5-global--FontFamily--text);
  line-height: var(--pf-v5-global--LineHeight--md);
}

.co-alert--margin-bottom-sm {
  margin-bottom: var(--pf-v5-global--spacer--sm);
}

.co-alert--margin-top {
  margin-bottom: 0;
  margin-top: 1rem;
}

.co-alert--scrollable .pf-v5-c-alert__description {
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

@media (min-height: 768px) {
  .co-alert--scrollable .pf-v5-c-alert__description {
    max-height: 200px;
  }
}

.co-code,
.co-iframe :not(pre)>code {
  --co-code--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --co-code--Color: var(--pf-v5-global--palette--light-blue-600);
  background-color: var(--co-code--BackgroundColor);
  color: var(--co-code--Color);
  padding: 3px var(--pf-v5-global--spacer--xs);
}

:where(.pf-v5-theme-dark) .co-code,
:where(.pf-v5-theme-dark) .co-iframe :not(pre)>code,
.co-iframe :where(.pf-v5-theme-dark) :not(pre)>code {
  --co-code--BackgroundColor: var(--pf-v5-global--palette--blue-700);
  --co-code--Color: var(--pf-v5-global--palette--light-blue-100);
}

.co-pre,
.co-iframe pre {
  background-color: var(--pf-v5-c-code-block--BackgroundColor);
  padding: var(--pf-v5-global--spacer--md);
  white-space: pre-wrap;
}

.co-copy-to-clipboard {
  position: relative;
}

.co-copy-to-clipboard__stacktrace-width-height {
  display: flex;
}

.co-copy-to-clipboard__stacktrace-width-height .co-copy-to-clipboard {
  width: 100%;
}

.co-copy-to-clipboard__stacktrace-width-height .co-copy-to-clipboard__text {
  max-height: 25vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.co-disabled {
  color: var(--pf-v5-global--disabled-color--100);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.co-disabled::before {
  bottom: 0;
  content: "";
  cursor: not-allowed;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 10;
}

.co-btn-file {
  overflow: hidden;
  position: relative;
}

.co-btn-file input[type=file] {
  background: #fff;
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  font-size: 100px;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  outline: none;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  z-index: 2;
}

.co-file-dropzone {
  position: relative;
}

.co-file-dropzone__flex {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.co-file-dropzone .co-file-dropzone-container {
  border: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--100);
  background: rgba(0,0,0,.3);
  bottom: 0;
  color: #fff;
  left: 0;
  margin: -3px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1001;
}

.co-file-dropzone .co-file-dropzone-container.co-file-dropzone--drop-over {
  border-color: #1fa7f8;
  color: #1fa7f8;
}

.co-file-dropzone__textarea {
  font-family: var(--pf-v5-global--FontFamily--monospace);
  min-height: 300px;
  width: 100%;
}

.co-file-dropzone__drop-text {
  font-size: 20px;
  font-weight: var(--pf-v5-global--FontWeight--bold);
  left: 50%;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.oc-kebab__popper-items {
  position: initial !important;
}

.oc-kebab__sub.pf-v5-c-dropdown__menu-item {
  --pf-v5-c-dropdown__menu-item--PaddingRight: var(--pf-v5-global--spacer--lg);
  position: relative;
}

.oc-kebab__arrow {
  position: absolute;
  right: var(--pf-v5-global--spacer--xs);
  top: 50%;
  transform: translateY(-50%);
}

.oc-kebab__icon {
  margin-right: calc(2*var(--pf-c-dropdown__toggle-icon--MarginRight));
}

.co-label.pf-v5-c-label {
  --pf-v5-c-label__text--MaxWidth: 100%;
}

.co-label,
.co-label-group>*,
.co-label-group li,
.co-label-group ul {
  min-width: 0;
}

.co-label-group {
  max-width: 100%;
}

.co-label__key,
.co-label__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tags-input {
  min-width: 100%;
  padding: var(--pf-v5-global--spacer--sm);
}

tags-input .tags {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: var(--pf-v5-global--spacer--xs);
  min-height: 120px;
}

tags-input .tags>span {
  display: contents;
}

tags-input .tags .input {
  background: transparent;
  border: 1px solid transparent;
  flex-grow: 1;
  outline: none;
}

tags-input .tags .input.invalid-tag {
  color: var(--pf-v5-global--danger-color--100);
}

.co-m-number-spinner__button {
  padding: 0 !important;
}

.co-m-number-spinner__input {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  display: inline-block;
}

.co-m-number-spinner__down-icon {
  margin-right: 10px;
}

.co-m-number-spinner__up-icon {
  margin-left: 10px;
}

.co-m-number-spinner__down-icon,
.co-m-number-spinner__up-icon {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  font-size: 20px;
  position: relative;
  top: 4px;
}

.request-size-input__unit {
  padding-left: 20px;
}

.cos-status-box {
  padding: 40px 20px;
}

.cos-status-box__title {
  color: var(--pf-v5-global--Color--100);
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.cos-status-box__title .cos-error-title {
  color: var(--pf-v5-global--danger-color--100);
}

.cos-status-box__detail {
  color: var(--pf-v5-global--Color--200);
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto;
}

.cos-status-box__access-denied-icon {
  margin: 10px;
}

.cos-status-box__loading-message {
  margin-top: var(--pf-v5-global--spacer--sm);
}

.cos-status-box--loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  flex-direction: column;
}

.co-m-loader--inline {
  display: inline-block;
  margin: 0;
  position: static;
}

.co-m-timeout-error {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
}

.loading-box {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.co-m-requirement__icon {
  margin-right: 4px;
}

.co-m-requirement__label {
  min-width: 0;
}

.co-m-requirement__link {
  align-items: baseline;
  display: flex;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.skeleton-chart {
  height: inherit;
  position: relative;
  width: 100%;
}

.skeleton-chart::after {
  background-image: url("../packages/console-shared/src/images/skeleton-chart.svg");
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.pf-v5-theme-dark .skeleton-chart::after {
  opacity: .2;
}

.skeleton-text {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  height: 24px;
  opacity: 0;
}

.skeleton-text::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

@keyframes loading-skeleton {
  0% {
    opacity: .15;
  }

  25% {
    opacity: .65;
  }

  100% {
    opacity: 1;
  }
}

.loading-skeleton--catalog {
  background: var(--pf-v5-global--BackgroundColor--100);
  border: 1px solid var(--pf-v5-global--BorderColor--300);
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0 1rem;
  position: relative;
}

.skeleton-catalog--grid {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 117px 6px 6px 0;
  padding: 30px 0 0 45px;
}

.skeleton-catalog--grid::before {
  content: "";
  background: var(--co-skeleton--Color);
  display: block;
  height: 40px;
  position: absolute;
  top: 52px;
  width: 150px;
}

.skeleton-catalog--list {
  flex: 0 0 235px;
}

.skeleton-catalog--list::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 45%, transparent 0);
  background-size: 100% 40px;
  content: "";
  display: block;
  height: 400px;
  left: 15px;
  position: absolute;
  top: 20px;
  width: 205px;
}

.skeleton-catalog--tile {
  height: 240px;
  margin: 0 24px 24px 0;
  width: 260px;
}

.skeleton-catalog--tile:empty::after {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  content: "";
  display: block;
  height: 100%;
  opacity: 0;
  width: 100%;
  background-image: linear-gradient(var(--co-skeleton--Color) 32px, transparent 0),linear-gradient(var(--co-skeleton--Color) 14px, transparent 0),linear-gradient(var(--co-skeleton--Color) 14px, transparent 0),linear-gradient(var(--co-skeleton--Color) 14px, transparent 0),linear-gradient(var(--co-skeleton--Color) 14px, transparent 0),radial-gradient(circle 20px at center, var(--co-skeleton--Color) 100%, transparent 0),linear-gradient(var(--co-skeleton--Color--300) 240px, transparent 0);
  background-position: 24px 75px,24px 132px,24px 152px,24px 172px,24px 192px,24px 24px,0 0;
  background-repeat: no-repeat;
  background-size: 142px 32px,168px 14px,174px 14px,185px 14px,125px 14px,46px 46px,100% 100%;
}

.skeleton-detail-view {
  padding: 1.5rem;
  height: 100%;
  width: 100%;
}

.skeleton-detail-view--column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px 0;
  min-width: 311px;
  width: 50%;
}

.skeleton-detail-view--grid {
  display: flex;
  flex-wrap: wrap;
}

.skeleton-detail-view--head::after {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  content: "";
  display: block;
  height: 30px;
  opacity: 0;
  width: 230px;
}

.skeleton-detail-view--tile {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  height: 75px;
  opacity: 0;
  width: 95%;
}

.skeleton-detail-view--tile::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.skeleton-detail-view--tile.skeleton-detail-view--tile-labels {
  height: 100px;
}

.skeleton-detail-view--tile.skeleton-detail-view--tile-plain::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),linear-gradient(var(--co-skeleton--Color) 24px, transparent 0);
  background-size: 80px 15px,200px 24px;
  background-position: 0 15px,0 35px;
}

.skeleton-detail-view--tile.skeleton-detail-view--tile-resource::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),radial-gradient(circle 12px at center, var(--co-skeleton--Color) 100%, transparent 0);
  background-position: 0 15px,30px 35px,0 35px;
  background-size: 80px 15px,75%,24px 24px;
}

.skeleton-detail-view--tile.skeleton-detail-view--tile-labels::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),linear-gradient(var(--co-skeleton--Color) 48px, transparent 0);
  background-position: 0 15px,0 35px;
  background-size: 80px 15px,90% 75px;
}

.skeleton-overview {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  position: relative;
}

.skeleton-overview--head {
  margin: 4px 0 10px;
  width: 100%;
}

.skeleton-overview--head::after {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  content: "";
  display: block;
  height: 24px;
  opacity: 0;
  top: -30px;
  width: 150px;
}

.skeleton-overview--tile {
  height: 71px;
  margin: 0 0 10px 0;
  width: 100%;
}

.skeleton-overview--tile::after {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  content: "";
  display: block;
  height: 100%;
  opacity: 0;
  width: 100%;
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),radial-gradient(circle 12px at center, var(--co-skeleton--Color) 100%, transparent 0),linear-gradient(var(--co-skeleton--Color) 71px, transparent 0);
  background-position: 50px 24px,96% 24px,15px 24px,0 0;
  background-repeat: no-repeat;
  background-size: 38% 24px,75px 24px,24px 24px,100% 100%;
}

.loading-skeleton--table::after,
.loading-skeleton--table::before {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  content: "";
  display: block;
  opacity: 0;
}

.loading-skeleton--table::after {
  background-image: linear-gradient(var(--pf-v5-global--BorderColor--300) 1px, transparent 0px),linear-gradient(var(--co-skeleton--Color) 26px, transparent 26px);
  background-size: 100% 46px,50% 46px;
  background-position: 50px 0%,0px 10px;
  min-height: 320px;
}

.loading-skeleton--table::before {
  background-image: linear-gradient(to right, var(--co-skeleton--Color), var(--co-skeleton--Color) 75%, transparent 75%, transparent 25%);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 57% 40px;
  height: 53px;
}

@media (min-width: 768px) {
  .loading-skeleton--table::before {
    background-size: 21% 40px;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .loading-skeleton--table::before {
    background-size: 37% 40px;
  }
}

.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main {
  background-color: var(--pf-v5-global--palette--black-1000);
}

.log-window__header {
  color: var(--pf-v5-global--Color--light-100);
  background-color: var(--pf-v5-global--BackgroundColor--dark-300);
  padding: 8px 10px 5px 10px;
}

.log-window-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.log-window__footer--hidden {
  visibility: hidden;
}

.resource-log {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: max-content auto;
}

.resource-log--fullscreen .co-toolbar {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  padding-left: 10px;
  padding-right: 10px;
}

.resource-log--fullscreen .resource-log__log-viewer-wrapper {
  overflow: auto;
}

.resource-log__alert-wrapper {
  display: grid;
  grid-template-rows: max-content;
}

.co-list-input__help-block {
  margin-bottom: 10px;
}

.co-list-input__row {
  display: flex;
  margin-bottom: 5px;
}

.co-list-input__value {
  flex-grow: 1;
}

.co-hint-block {
  background-color: var(--pf-v5-global--palette--blue-50);
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--palette--blue-100);
  color: var(--pf-v5-global--Color--100);
  padding: var(--pf-v5-global--spacer--lg);
}

:where(.pf-v5-theme-dark) .co-hint-block {
  background-color: var(--pf-v5-global--BackgroundColor--400);
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.co-field-level-help {
  margin-left: 5px;
}

.co-field-level-help:hover .co-field-level-help__icon {
  color: var(--pf-v5-global--Color--dark-100);
}

.co-field-level-help .co-field-level-help__icon {
  color: var(--pf-v5-global--Color--dark-200);
  font-size: 12px;
}

.co-example-docker-command__popover {
  width: 600px;
}

.co-images-stream-tag-timeline {
  font-size: 15px;
  margin: 20px 1.5rem;
  padding-left: 0;
  width: auto;
}

@media (max-width: 767px) {
  .co-images-stream-tag-timeline {
    margin: 20px 10px;
  }
}

.co-images-stream-tag-timeline li {
  height: auto;
  list-style: none;
  position: relative;
}

.co-images-stream-tag-timeline__circle-icon,
.co-images-stream-tag-timeline__square-icon {
  color: var(--pf-v5-global--BorderColor--100);
  left: 8px;
  position: relative;
}

.co-images-stream-tag-timeline__info {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  border-radius: 3px;
  line-height: 30px;
  margin: 8px 0 24px 40px;
  padding: 20px;
  width: 100%;
}

.co-images-stream-tag-timeline__info>div:first-of-type {
  font-size: var(--pf-v5-global--FontSize--sm);
  padding-bottom: 16px;
}

.co-images-stream-tag-timeline__item-row {
  display: flex;
  margin: 2px 0;
}

.co-images-stream-tag-timeline__line {
  border: var(--pf-v5-global--BorderWidth--md) solid var(--pf-v5-global--BorderColor--100);
  left: 14px;
  position: relative;
}

.co-images-stream-tag-timeline__timestamp {
  margin-left: 35px;
}

.pf-v5-c-about-modal-box dt {
  color: var(--pf-v5-global--color--100);
  font-size: var(--pf-v5-c-content--FontSize);
  margin-bottom: var(--pf-v5-global--spacer--md);
  text-transform: none;
}

.pf-v5-c-about-modal-box dd {
  margin-bottom: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-about-modal-box p {
  color: var(--pf-v5-global--color--100);
  margin-bottom: var(--pf-v5-global--spacer--xl);
}

.co-about-modal__alert {
  margin-bottom: var(--pf-v5-global--spacer--xl);
}

@keyframes build-progress-line {
  to {
    width: 100%;
  }
}

@keyframes build-progress-rail {
  to {
    transform: translateX(400%);
  }
}

@keyframes build-progress {
  to {
    transform: rotate(180deg);
  }
}

@keyframes pipeline-stage-fadeIcon {
  to {
    opacity: 1;
  }
}

@keyframes pipeline-stage-fadeOut {
  to {
    background-color: transparent;
  }
}

.build-pipeline {
  border: 1px solid #868789;
  font-size: 13px;
  margin-bottom: 30px;
}

.build-pipeline:first-child {
  border-top-width: 1px;
}

@media (min-width: 600px) {
  .build-pipeline {
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
  }
}

.build-pipeline__container {
  flex: 1 1 auto;
  overflow: hidden;
}

.build-pipeline__stages {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 5px;
}

.build-pipeline__stage {
  flex: 0 0 auto;
  min-height: 96px;
  padding: 15px 10px 30px 10px;
  position: relative;
  width: 100%;
}

.build-pipeline__stage:before {
  bottom: 0;
  color: #797a7c;
  content: "↓";
  font-size: 22px;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-align: center;
}

.build-pipeline__stage:last-child:before {
  display: none;
}

@media (min-width: 480px) {
  .build-pipeline__stage {
    padding-right: 52px;
    padding-bottom: 15px;
    width: 33.3333333333%;
  }

  .build-pipeline__stage:before {
    bottom: auto;
    content: "→";
    left: auto;
    right: 14px;
    top: 40%;
  }
}

@media (min-width: 992px) {
  .build-pipeline__stage {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .build-pipeline__stage {
    width: 20%;
  }
}

@media (min-width: 1400px) {
  .build-pipeline__stage {
    width: 16.6666666667%;
  }
}

@media (min-width: 1600px) {
  .build-pipeline__stage {
    width: 14.2857142857%;
  }
}

.build-pipeline__stage--none {
  align-items: center;
  display: flex;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .build-pipeline__stage--none {
    justify-content: center;
  }
}

.build-pipeline__stage-name,
.build-pipeline__stage-time,
.build-pipeline__stage-actions {
  font-size: 13px;
  text-align: center;
}

.build-pipeline__stage-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 13px;
}

.build-pipeline__stage-time,
.build-pipeline__stage-actions {
  margin-top: 12px;
}

.build-pipeline__stage-time--in-progress,
.build-pipeline__stage-actions--in-progress {
  color: #777;
}

.build-pipeline__status-icon--cancelled {
  color: #868789;
}

.build-pipeline__summary {
  border-bottom: 1px solid #868789;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 600px) {
  .build-pipeline__summary {
    border-bottom-width: 0;
    border-right: 1px solid #868789;
    flex: 0 0 125px;
    flex-direction: column;
    justify-content: center;
  }
}

.build-pipeline__status-bar .build-pipeline__animation-line:before,
.build-pipeline__status-bar .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar .build-pipeline__circle-clip2:before {
  background-color: #868789;
}

.build-pipeline__status-bar .build-pipeline__circle-inner-fill {
  background-color: #fff;
  opacity: 0;
}

.build-pipeline__status-bar--success .build-pipeline__animation-line:before,
.build-pipeline__status-bar--success .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--success .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--success .build-pipeline__circle-inner-fill {
  background-color: var(--pf-v5-chart-color-green-400);
}

.build-pipeline__status-bar--success .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInSuccess 0s .7s linear forwards;
}

.build-pipeline__status-bar--success .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--failed .build-pipeline__animation-line:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-inner-fill {
  background-color: #c9190b;
}

.build-pipeline__status-bar--failed .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInFailed 0s .7s linear forwards;
}

.build-pipeline__status-bar--failed .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-line:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-inner-fill {
  background-color: #868789;
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInNotExecuted 0s .7s linear forwards;
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--paused-pending-input .build-pipeline__animation-line:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-inner-fill {
  background-color: #f0ab00;
}

.build-pipeline__status-bar--paused-pending-input .build-pipeline__animation-circle:after {
  content: "";
  font-size: 11px;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-line:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-inner-fill {
  background-color: #868789;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInAborted 0s .7s linear forwards;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-inner-fill {
  background-color: #06c;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInInProgress 0s .7s linear forwards;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line {
  overflow: hidden;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line:before {
  animation: build-progress-rail 5s .5s linear infinite;
  background-color: #06c;
  transform: translateX(-100%);
  width: 25%;
}

.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-inner-fill {
  background-color: #06c;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInProgress 0s .7s linear forwards;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: -9px;
}

.build-pipeline__log-link,
.build-pipeline__timestamp {
  font-size: 12px;
}

.build-pipeline__animation-line {
  background: #868789;
  height: 4px;
  position: relative;
  width: 100%;
}

.build-pipeline__animation-line:before {
  animation: build-progress-line .35s ease-in forwards;
  content: "";
  height: 100%;
  position: absolute;
  width: 0;
}

.build-pipeline__animation-circle {
  background: #868789;
  border-radius: 9px;
  height: 18px;
  margin-top: -11px;
  position: relative;
  transform: rotate(-90deg);
  width: 18px;
}

.build-pipeline__animation-circle:after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  animation: pipeline-stage-fadeIcon .1s .875s linear forwards;
  color: #fff;
  font-size: 10px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.build-pipeline__circle-clip2,
.build-pipeline__circle-clip1 {
  position: absolute;
  z-index: -9;
}

.build-pipeline__circle-clip2:before,
.build-pipeline__circle-clip1:before {
  border-radius: 9px;
  content: "";
  height: 18px;
  position: absolute;
  transform: rotate(0);
  width: 18px;
}

.build-pipeline__circle-clip1 {
  clip: rect(0, 18px, 18px, 9px);
}

.build-pipeline__circle-clip1:before {
  animation: build-progress .175s .35s linear forwards;
  clip: rect(0, 9px, 18px, 0);
}

.build-pipeline__circle-clip2 {
  clip: rect(0, 9px, 18px, 0);
}

.build-pipeline__circle-clip2:before {
  animation: build-progress .175s .525s linear forwards;
  clip: rect(0, 18px, 18px, 9px);
}

.build-pipeline__circle-inner {
  animation: pipeline-stage-fadeOut .1s .7s linear forwards;
  background-color: #fff;
  border-radius: 9px;
  height: 10px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 10px;
}

.build-pipeline__circle-inner-fill {
  border-radius: 50%;
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.co-p-configure-ports__port-number {
  max-width: 100% !important;
}

.co-detail-table {
  text-align: center;
  border-width: 1px;
  max-width: 800px;
  white-space: nowrap;
}

.co-detail-table--lg {
  max-width: 900px;
}

.co-detail-table__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
}

.co-detail-table,
.co-detail-table__section {
  border-color: var(--pf-v5-global--BorderColor--300);
  border-style: solid;
}

.co-detail-table__section {
  align-items: center;
  border-width: 1px 1px 0 0;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  padding: 20px 10px;
  margin-right: -1px;
  margin-top: -1px;
}

.co-detail-table__section--last {
  border-right-width: 0;
}

.co-detail-table__section-header {
  margin-top: 4px;
  margin-bottom: 4px;
}

.co-detail-table__bracket {
  width: 12px;
  height: 48px;
  background: url("./imgs/bracket.svg") center center no-repeat;
  background-size: 12px 48px;
  padding: 0 20px;
}

.co-detail-table__breakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 47px;
  text-align: left;
}

.dropdown--full-width,
.dropdown--full-width .pf-v5-c-dropdown__toggle {
  justify-content: space-between;
  width: 100%;
}

.dropdown-button--icon-only {
  height: 34.5px !important;
  margin-left: -1px;
  min-width: auto !important;
}

.dropdown-button--icon-only .pf-v5-c-dropdown {
  --pf-c-dropdown__toggle-icon--MarginRight: 0 !important;
  --pf-c-dropdown__toggle-icon--MarginLeft: 0 !important;
}

.dropdown__disabled {
  color: var(--pf-v5-global--disabled-color--100);
  cursor: not-allowed;
}

.dropdown-menu__divider {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  height: 1px;
  flex-grow: 1;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu__header {
  color: var(--pf-global--Color--100);
  flex-grow: 1;
  font-size: 13px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.dropdown-menu--text-wrap {
  max-width: 100%;
}

.dropdown-menu--text-wrap li a,
.dropdown-menu--text-wrap li button {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.favorite {
  color: gold;
}

.co-namespace-bar {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 0 1rem;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .co-namespace-bar {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-namespace-bar__items {
  align-items: center;
  display: flex;
}

.co-namespace-bar__items .pf-m-plain {
  color: inherit;
}

.co-namespace-bar__items .pf-m-plain:hover,
.co-namespace-bar__items .pf-m-plain:focus {
  text-decoration: none;
}

.co-namespace-bar--no-project {
  padding-left: var(--pf-v5-global--spacer--sm);
}

@media screen and (max-width: 1200px) {
  .co-namespace-bar--no-project {
    margin-left: -0.5rem;
  }
}

.co-namespace-selector {
  margin-right: 20px;
  max-width: 60%;
}

.co-namespace-selector .pf-v5-c-dropdown__toggle.pf-m-plain {
  font-size: 15px;
  --pf-v5-c-dropdown__toggle--PaddingBottom: 7px;
  --pf-v5-c-dropdown__toggle--PaddingLeft: 0;
  --pf-v5-c-dropdown__toggle--PaddingRight: 0;
}

@media (min-width: 768px) {
  .co-namespace-selector .pf-v5-c-dropdown__toggle.pf-m-plain {
    font-size: 16px;
    --pf-v5-c-dropdown__toggle--PaddingBottom: 9px;
  }
}

.co-namespace-selector .pf-v5-c-dropdown__toggle.pf-m-plain:not(:disabled) {
  color: inherit;
}

.co-namespace-selector .pf-v5-c-dropdown__toggle.pf-m-plain__titlePrefix {
  margin-right: 6px;
}

.pf-v5-c-dropdown .co-m-resource-icon {
  margin-left: 2px;
}

.pf-v5-c-dropdown__content-wrap {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  min-width: 0;
}

.pf-v5-c-dropdown__menu {
  list-style: none;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) {
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-md {
    right: 0;
  }
}

.pf-v5-c-dropdown__toggle {
  max-width: 100%;
}

.pf-v5-c-dropdown__toggle .pf-c-dropdown__toggle-icon {
  flex: 0 0 auto;
}

.pf-v5-c-dropdown__toggle-text {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.pf-v5-c-dropdown__toggle-text .co-resource-item__resource-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pf-v5-c-dropdown__toggle-text .co-resource-item__resource-namespace {
  display: none !important;
}

.co-global-notification {
  background-color: #06c;
  color: #fff;
  padding: 4px 1rem;
  text-align: center;
}

@media (min-width: 768px) {
  .co-global-notification {
    padding: 6px 1.5rem;
  }
}

.co-global-notification+.co-global-notification {
  margin-top: 1px;
}

.co-global-notification a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.co-global-notification button {
  color: #fff !important;
}

.co-global-notification__text {
  margin: 0;
}

.co-global-notification__impersonate-name {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.co-global-notification__impersonate-kind {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  padding-right: 20px;
}

.co-global-telemetry-notification {
  background-color: #06c;
  color: #fff;
  padding: 4px 1rem;
  text-align: center;
}

.co-global-telemetry-notification__content {
  display: inline-flex;
  align-items: center;
}

.co-global-telemetry-notification__button {
  margin-left: var(--pf-v5-global--spacer--md);
}

.co-import-yaml-results-page {
  height: 100%;
}

.co-import-yaml-results-page__footer {
  padding-top: 25px;
  text-align: center;
}

.co-import-yaml-results-page__main {
  margin: 0 1rem;
}

@media (min-width: 768px) {
  .co-import-yaml-results-page__main {
    margin: 1.5rem;
    max-width: 600px;
    min-width: 375px;
    width: 100%;
  }
}

.co-import-yaml-status {
  margin-bottom: 20px;
}

.co-resource-list__item {
  height: 100%;
  position: relative;
  transition: all .25s;
}

.co-resource-list__item .dropdown-kebab-pf {
  position: absolute;
  right: 15px;
  top: 7px;
}

.co-resource-list__item--expanded {
  display: inline-block;
  margin-top: 10px;
}

.compaction-btn {
  margin-right: 0 !important;
}

.pf-v5-theme-dark .query-browser__tooltip-line,
.pf-v5-theme-dark .query-browser__tooltip-arrow,
.pf-v5-theme-dark .query-browser__tooltip {
  --pf-v5-global--BackgroundColor--dark-100: var(--pf-v5-global--palette--black-1000);
}

.co-alert-manager {
  margin-bottom: var(--pf-v5-global--spacer--md);
}

.co-alert-manager-config__edit-alert-routing-btn {
  margin-bottom: 10px;
}

.co-alert-manager-config__save-as-default-label {
  padding-left: 4px;
  padding-right: 4px;
}

.co-resource-item.co-resource-item--monitoring-alert {
  margin: 0 5px;
}

.co-routing-label-editor__error-message {
  margin-top: -15px;
}

.pf-v5-c-modal-box .custom-time-range-modal {
  overflow: visible;
  padding: var(--pf-v5-c-modal-box__body--PaddingTop) var(--pf-v5-c-modal-box__body--PaddingLeft);
}

.pf-v5-c-modal-box .custom-time-range-modal-footer {
  padding-top: var(--pf-v5-c-modal-box__body--PaddingTop);
}

.graph-empty-state {
  min-height: 310px;
}

.graph-empty-state__loaded {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graph-wrapper__horizontal-bar {
  padding-right: 10px;
}

.graph-wrapper.graph-wrapper--query-browser {
  padding: 5px 15px 15px 60px;
}

.graph-wrapper.graph-wrapper--query-browser--with-legend {
  min-height: 305px;
}

.horizontal-scroll {
  overflow-x: auto;
  padding-left: var(--pf-v5-global--spacer--md);
  padding-right: var(--pf-v5-global--spacer--md);
}

.horizontal-scroll:before,
.horizontal-scroll:after {
  bottom: 0;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  width: var(--pf-v5-global--spacer--md);
  z-index: 1;
}

.horizontal-scroll:before {
  background: linear-gradient(to right, var(--pf-v5-global--BackgroundColor--100), rgba(255, 255, 255, 0));
  left: 0;
}

.horizontal-scroll:after {
  background: linear-gradient(to left, var(--pf-v5-global--BackgroundColor--100), rgba(255, 255, 255, 0));
  right: 0;
}

.monitoring-breadcrumbs {
  padding-bottom: var(--pf-v5-global--spacer--sm);
}

.monitoring-dashboards__card {
  height: calc(100% - 20px);
  margin: 0 0 20px 0;
}

.monitoring-dashboards__card .co-dashboard-card__body--dashboard {
  padding: 10px 15px 10px 10px;
}

.monitoring-dashboards__card.co-dashboard-card--gradient .pf-v5-c-card__body {
  max-height: 350px;
  overflow: scroll;
}

.monitoring-dashboards__card.co-dashboard-card--gradient:after {
  width: calc(100% - 10px);
}

.monitoring-dashboards__card .query-browser__wrapper {
  border: 0;
  margin: 0;
  min-height: 240px;
  padding: 0;
}

.monitoring-dashboards__card-body-content {
  height: 100%;
}

.monitoring-dashboards__card-header {
  flex: 0 0 auto;
}

.monitoring-dashboards__dropdown-button {
  max-width: 280px !important;
  min-width: 100px;
}

@media (min-width: 768px) {
  .monitoring-dashboards__dropdown-button {
    max-width: 100% !important;
  }
}

.monitoring-dashboards__dropdown-title {
  text-transform: capitalize;
}

.monitoring-dashboards__dropdown-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.monitoring-dashboards__dropdown-wrap .monitoring-dashboards__dashboard_dropdown_item {
  display: flex;
  justify-content: space-between;
}

.monitoring-dashboards__dropdown-wrap .monitoring-dashboards__dashboard_dropdown_tag {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  line-height: 13px;
  margin-left: 20px;
}

.monitoring-dashboards__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.monitoring-dashboards__label-column-header {
  min-width: 175px;
}

@media (min-width: 1200px) {
  .monitoring-dashboards__label-column-header {
    min-width: 225px;
  }
}

.monitoring-dashboards__legend-wrap {
  height: 75px;
  padding-top: 1px;
}

.monitoring-dashboards__legend-wrap svg {
  max-height: 65px;
}

.monitoring-dashboards__dropdowns {
  display: flex;
}

.monitoring-dashboards__options {
  display: flex;
  margin-right: -20px;
}

.monitoring-dashboards__panel {
  padding: 0 10px;
}

.monitoring-dashboards__panel--max-1 {
  width: 100%;
}

@media (max-width: 567px) {
  .monitoring-dashboards__panel--max-2,
  .monitoring-dashboards__panel--max-3,
  .monitoring-dashboards__panel--max-4 {
    min-width: 100%;
  }
}

@media (min-width: 567px)and (max-width: 1199px) {
  .monitoring-dashboards__panel--max-2 {
    width: 100%;
  }

  .monitoring-dashboards__panel--max-3,
  .monitoring-dashboards__panel--max-4 {
    flex: 1 0 50%;
    min-width: 50%;
  }
}

@media (min-width: 1200px) {
  .monitoring-dashboards__panel--max-2 {
    flex: 1 0 50%;
    min-width: 50%;
  }

  .monitoring-dashboards__panel--max-3 {
    flex: 1 0 33%;
    min-width: 33%;
  }

  .monitoring-dashboards__panel--max-4 {
    flex: 0 0 25%;
    min-width: 25%;
  }
}

.monitoring-dashboards__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.monitoring-dashboards__single-stat {
  font-size: var(--pf-v5-global--FontSize--3xl);
}

.monitoring-dashboards__table {
  table-layout: auto;
  width: auto;
}

.monitoring-dashboards__table-container {
  overflow-x: auto;
}

.monitoring-dashboards__variable-dropdown .pf-v5-c-dropdown__menu,
.monitoring-dashboards__variable-dropdown .pf-v5-c-select__menu {
  max-height: 60vh;
  overflow-y: auto;
}

.monitoring-dashboards__variable-dropdown .pf-m-search {
  min-width: 140px;
}

.monitoring-dashboards__variables {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.monitoring-description,
.monitoring-label-list,
.monitoring-timestamp {
  line-height: 18px;
  margin-top: 4px;
}

.monitoring-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.monitoring-grid-head {
  padding-bottom: 5px;
}

.monitoring-header-link {
  font-size: 14px;
  margin-left: 14px;
}

.monitoring-heading {
  display: flex;
  justify-content: space-between;
}

.monitoring-icon-wrap {
  margin-right: 10px;
}

.monitoring-alert-detail-toolbar.pf-v5-c-toolbar {
  padding-top: 0;
}

.monitoring-alert-detail-toolbar.pf-v5-c-toolbar .pf-v5-c-toolbar__content {
  padding: 0;
}

.monitoring-alert-detail-toolbar.pf-v5-c-toolbar .co-section-heading {
  margin: 0;
}

.monitoring-silence-alert {
  max-width: 950px;
}

.monitoring-silence-alert__label-options {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .monitoring-silence-alert__label-options {
    padding-top: 26px;
  }
}

.monitoring-silence-alert__paragraph {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}

.monitoring-state-icon--pending {
  color: #26292d;
}

.monitoring-timestamp {
  display: flex;
  flex-flow: row wrap;
  font-size: 13px;
}

.prometheus-template-tag {
  margin: 0 2px;
}

.query-browser__autocomplete-match {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.query-browser__clear-icon {
  font-size: 18px !important;
  padding: 4px !important;
  position: absolute !important;
  right: 0;
  top: 0;
  width: 30px;
}

.query-browser__controls {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.query-browser__controls .pf-v5-c-check {
  --pf-v5-c-check__input--MarginTop: 0;
}

.query-browser__controls--left {
  display: flex;
}

.query-browser__reduced-resolution.pf-v5-c-alert {
  align-content: center;
}

.query-browser__controls--right {
  display: flex;
  margin-left: auto;
}

.query-browser__dropdown--subtitle {
  padding: 1px 10px;
}

.query-browser__expand-button {
  border: none;
  height: 30px;
  padding: 0 14px !important;
}

.query-browser__expand-icon {
  font-size: 30px;
  vertical-align: middle;
}

.query-browser__inline-control {
  margin-left: 10px;
  margin-right: 10px;
}

.query-browser__loading {
  margin: auto 0;
  min-width: 20px;
}

.query-browser__metrics-dropdown-menu {
  max-height: 25vh;
  overflow: auto;
  width: 100%;
}

.query-browser__query {
  line-height: 1;
  margin: 0 15px 0 0;
  width: 100%;
}

.query-browser__query .pf-v5-c-form__helper-text {
  margin-top: var(--pf-v5-global--spacer--xs);
}

.query-browser__query-controls {
  align-items: center;
  border: solid var(--pf-v5-global--BorderColor--100);
  border-width: 1px 0;
  display: flex;
  padding: 10px 0;
}

.query-browser__query>.query-browser__query-input {
  padding-right: 28px !important;
  resize: vertical;
  font-family: var(--pf-v5-c-code-block__pre--FontFamily),monospace;
  font-size: var(--pf-v5-c-code-block__pre--FontSize);
}

.query-browser__query>.query-browser__query-input:focus-within {
  --pf-v5-c-form-control--BorderBottomColor: var( --pf-v5-c-form-control--focus--BorderBottomColor );
  padding-bottom: var(--pf-v5-c-form-control--focus--PaddingBottom);
  border-bottom-width: var(--pf-v5-c-form-control--focus--BorderBottomWidth);
}

.query-browser__series-btn {
  border: var(--pf-v5-global--BorderWidth--sm) solid transparent;
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  height: 20px;
  padding: 0 !important;
  width: 20px;
}

.query-browser__series-btn--disabled {
  border: var(--pf-v5-global--BorderWidth--sm) solid #888 !important;
}

.query-browser__series-btn-wrap {
  border: var(--pf-v5-global--BorderWidth--sm) solid transparent;
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  display: flex;
  height: 26px;
  padding: 2px;
  width: 26px;
}

.query-browser__series-btn-wrap:hover {
  background-color: var(--pf-v5-global--active-color--200);
  border-color: var(--pf-v5-global--active-color--100);
}

.query-browser__span {
  max-width: 130px;
}

.query-browser__span .query-browser__span-dropdown-item {
  width: 129px;
}

.query-browser__table {
  border-left: 3px solid transparent;
  margin-bottom: 25px;
}

.query-browser__table--expanded {
  border-left-color: var(--pf-v5-global--active-color--100);
}

.query-browser__table tr>:first-child {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  vertical-align: middle;
}

.query-browser__table-wrapper {
  position: relative;
  width: 100%;
}

.query-browser__table-wrapper table {
  table-layout: auto;
}

.query-browser__table-wrapper .query-browser__series-select-all-btn {
  margin-top: var(--pf-v5-global--spacer--md);
}

.query-browser__table-message {
  border-bottom: solid 1px var(--pf-v5-global--BorderColor--100);
  padding: 10px 20px;
}

.query-browser__toggle-graph-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--pf-v5-global--spacer--xs);
}

.query-browser__tooltip {
  background-color: var(--pf-v5-global--BackgroundColor--dark-100);
  color: #eee;
  font-size: 12px;
  overflow-x: hidden;
  padding: 10px;
}

.query-browser__tooltip-arrow {
  border-bottom: 12px solid transparent;
  border-right: 12px solid var(--pf-v5-global--BackgroundColor--dark-100);
  border-top: 12px solid transparent;
  height: 0;
  width: 0;
}

.query-browser__tooltip-line {
  stroke: var(--pf-v5-global--BackgroundColor--dark-100);
}

.query-browser__tooltip-series {
  display: flex;
}

.query-browser__tooltip-series .query-browser__series-btn {
  flex: 0 0 12px;
  height: 12px;
  margin-top: 4px;
  margin-right: 3px;
}

.query-browser__tooltip-time {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.query-browser__tooltip-value {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;
}

.query-browser__tooltip-wrap {
  align-items: center;
  display: flex;
  height: 100%;
}

.query-browser__tooltip-wrap--left {
  flex-direction: row-reverse;
}

.query-browser__tooltip-wrap--left .query-browser__tooltip-arrow {
  border-left: 12px solid var(--pf-v5-global--BackgroundColor--dark-100);
  border-right: none;
}

.query-browser__wrapper {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  margin: 0 0 20px 0;
  overflow: visible;
  padding: 10px;
  width: 100%;
}

.query-browser__wrapper--hidden {
  border-bottom: none;
  border-top: none;
  height: 0;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.query-browser__zoom {
  cursor: ew-resize;
  position: relative;
  height: 200px;
}

.query-browser__zoom:focus {
  outline: none;
}

.query-browser__zoom-overlay {
  background-color: rgba(0,0,0,.2);
  bottom: 0;
  position: absolute;
  top: 0;
  z-index: 1050;
}

.co-actions {
  align-items: flex-end;
  display: flex;
  font-family: var(--pf-v5-global--FontFamily--text);
}

@media (max-width: 480px) {
  .co-actions {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .co-action-buttons__btn {
    margin-bottom: 10px;
  }
}

.co-action-buttons__btn,
.co-actions-menu {
  margin-left: 10px;
}

.co-m-nav-title {
  margin-top: 1.5rem;
  padding: 0 1rem 0;
}

@media (min-width: 1200px) {
  .co-m-nav-title {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .co-m-nav-title--row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.co-m-nav-title--detail {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  flex-shrink: 0;
}

.co-m-nav-title--breadcrumbs {
  margin-top: 0;
}

.rbac-rule-resource {
  align-items: baseline;
  display: inline-flex;
  margin-right: 20px;
  min-width: 0;
}

.rbac-rule-resource__label {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  min-width: 0;
}

.rbac-rule-resource .co-m-resource-icon {
  flex-shrink: 0;
}

.rbac-rule-resources {
  display: flex;
  flex-wrap: wrap;
}

.rbac-rule-row:not(:last-of-type) {
  padding-bottom: 5px;
}

.resource-separator {
  margin: var(--pf-v5-global--spacer--sm) 0;
}

.edit-rule .rule-row {
  margin-top: 30px;
}

.edit-rule .help-text {
  color: #6a6e73;
  position: relative;
  top: -3px;
  left: 20px;
}

.edit-rule .checkbox-label {
  margin-bottom: 5px;
}

.edit-rule .rbac-minor {
  margin-bottom: var(--pf-v5-global--spacer--md);
  margin-top: var(--pf-v5-global--spacer--sm);
}

.edit-rule .newspaper-columns {
  columns: 3 auto;
  margin-top: 20px;
}

.edit-rule .text-input {
  max-width: 500px;
  margin-top: 20px;
}

.co-create-secret-warning {
  color: #ec7a08;
  margin-bottom: 10px;
}

.co-create-secret__form-entry-wrapper {
  position: relative;
}

.co-create-secret-form__link--remove-entry {
  display: flex;
  flex-flow: row-reverse;
}

.co-create-image-secret__form {
  margin-bottom: 25px;
}

.co-create-secret__form-entry-wrapper+.co-create-secret__form-entry-wrapper {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding-top: 10px;
}

.co-create-generic-secret__form .co-file-dropzone__textarea {
  min-height: 80px;
}

.co-m-resource-icon {
  background-color: #2b9af3;
  border-radius: 20px;
  color: var(--pf-v5-global--palette--white);
  display: inline-block;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  margin-right: 4px;
  min-width: 18px;
  padding: 1px 4px;
  text-align: center;
  white-space: nowrap;
}

.co-m-resource-icon--lg {
  font-size: 17px;
  line-height: 20px;
  margin-right: 7px;
  min-width: 24px;
  padding: 0 7px;
}

.co-resource-icon--fixed-width {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 50px;
}

.co-m-resource-application {
  background-color: #3e8635;
}

.co-m-resource-clusterrole,
.co-m-resource-role {
  background-color: #795600;
}

.co-m-resource-clusterrolebinding,
.co-m-resource-rolebinding {
  background-color: #008bad;
}

.co-m-resource-daemonset,
.co-m-resource-deployment,
.co-m-resource-deploymentconfig,
.co-m-resource-job,
.co-m-resource-petset,
.co-m-resource-replicaset,
.co-m-resource-replicationcontroller {
  background-color: #004080;
}

.co-m-resource-machine,
.co-m-resource-machineautoscaler,
.co-m-resource-machineclass,
.co-m-resource-machineconfig,
.co-m-resource-machineconfigpool,
.co-m-resource-machinedeployment,
.co-m-resource-machinehealthcheck,
.co-m-resource-machineset,
.co-m-resource-node,
.co-m-resource-policy {
  background-color: #8476d1;
}

.co-m-resource-alertrule,
.co-m-resource-configmap {
  background-color: #40199a;
}

.co-m-resource-alert,
.co-m-resource-container {
  background-color: #2b9af3;
}

.co-m-resource-pod {
  background-color: #009596;
}

.co-m-resource-namespace,
.co-m-resource-project {
  background-color: #1e4f18;
}

.co-m-resource-secret {
  background-color: #c46100;
}

.co-m-resource-alertmanager {
  background-color: #773d00;
}

.co-m-resource-service {
  background-color: #6ca100;
}

.co-m-resource-serviceaccount {
  background-color: #40199a;
}

.co-m-resource-ingress {
  background-color: #40199a;
}

@keyframes co-resource-icon__deleting {
  to {
    color: #36373a;
  }
}

.co-resource-item {
  align-items: baseline;
  display: flex;
  min-width: 0;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.co-resource-item--inline {
  display: inline-flex;
  margin: 0 20px 0 0;
}

.co-resource-item--truncate {
  white-space: nowrap;
}

.co-resource-item--truncate .co-resource-item__resource-name {
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.co-resource-item__deleting {
  animation: co-resource-icon__deleting 1.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) alternate infinite;
  flex: 0 0 auto;
  font-size: 13px;
  font-weight: var(--pf-v5-global--FontWeight--bold);
  margin: 0 0 0 15px;
  overflow: hidden;
  padding: 0 0 2px;
  position: relative;
  white-space: nowrap;
}

.co-resource-item__help-text {
  margin-left: 3px;
  white-space: nowrap;
}

.co-resource-item__resource-name {
  min-width: 0;
}

.co-namespace-summary__description {
  max-height: 15rem;
  overflow-y: auto;
  padding-right: var(--pf-v5-global--spacer--sm);
}

.co-search {
  padding-bottom: 30px;
}

@media (max-width: 575px) {
  .co-search .pf-v5-c-input-group {
    display: block;
  }

  .co-search .pf-v5-c-input-group .pf-v5-c-dropdown__toggle {
    margin-bottom: 2px;
    width: 100%;
  }
}

.co-search-group {
  display: flex;
  flex-direction: column;
}

@media (min-width: 480px) {
  .co-search-group {
    flex-direction: row;
  }
}

.co-search-group__filter {
  flex: 1 0 auto;
}

.co-search-group__accordion-label {
  display: flex;
  align-items: center;
  flex: 1;
}

.co-search-group__accordion-label .small {
  font-weight: normal !important;
}

.co-search-group__accordion-label .text-muted {
  margin-left: 10px;
}

.co-search__accordion-toggle .pf-v5-c-accordion__toggle-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 480px) {
  .co-search__accordion-toggle .pf-v5-c-accordion__toggle-text {
    flex-direction: row;
  }
}

.co-search__accordion-toggle .co-search-group__pin-toggle {
  padding-left: var(--pf-v5-global--spacer--xs);
  padding-right: var(--pf-v5-global--spacer--xs);
  margin-right: var(--pf-v5-global--spacer--sm);
  text-align: left;
}

.co-search__accordion-toggle .co-search-group__pin-toggle__icon {
  margin-right: var(--pf-v5-global--spacer--xs);
}

.co-search-group__resource {
  margin: 0 15px 15px 0;
  width: auto !important;
}

.co-type-selector .pf-v5-c-select__menu {
  min-width: 290px;
}

@media (min-width: 480px) {
  .co-type-selector .pf-v5-c-select__menu {
    min-width: 420px;
  }
}

.co-type-selector .pf-v5-c-select__menu .co-resource-item__tech-dev-preview {
  color: red;
  margin-left: 10px;
}

.span--icon__right-margin {
  margin-right: 6px;
}

.co-select-group-close-button {
  margin-left: 360px;
  top: 2px;
  z-index: 1;
}

.co-select-group-dismissible {
  top: -32px;
  position: relative;
}

.co-select-group-divider {
  margin-top: -22px !important;
}

.co-toggle-reveal-value {
  display: flex;
  flex-direction: column;
}

.co-toggle-reveal-value__btn {
  align-self: flex-end;
}

.pf-v5-c-form-control {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.co-row-filter {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  margin-bottom: 2rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .co-row-filter::before {
    border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
    border-bottom-right-radius: var(--pf-v5-global--BorderRadius--sm);
    border-top-right-radius: var(--pf-v5-global--BorderRadius--sm);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.co-row-filter .pf-v5-c-toggle-group {
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .co-row-filter .pf-v5-c-toggle-group__item:last-child .pf-v5-c-toggle-group__button,
  .co-row-filter .pf-v5-c-toggle-group__item:last-child .pf-v5-c-toggle-group__button::before {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.co-row-filter__items {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .co-row-filter__items {
    padding: 0 var(--pf-v5-global--spacer--md);
  }
}

.co-row-filter__number-bubble {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  display: inline-block;
  line-height: var(--pf-v5-global--LineHeight--sm);
  margin-right: var(--pf-v5-global--spacer--sm);
  padding: 2px 5px 0;
  text-align: center;
}

.co-row-filter__number-bubble.co-row-filter__number-bubble--active {
  border-color: var(--pf-v5-global--primary-color--100);
}

.co-m-route-tls-reveal__title {
  display: flex;
  justify-content: space-between;
}

.co-m-route-ingress-status+.co-m-route-ingress-status {
  margin-top: 40px;
}

.service-ips .co-ip-header {
  padding: 10px 0 5px 20px;
}

.service-ips .co-ip-row {
  margin-top: -1px;
  padding: 20px 0 5px 20px;
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.service-ips .co-ip-row .ip-name {
  margin-bottom: 5px;
}

.port-list {
  padding-left: 0;
  list-style: none;
}

.co-sysevent-icon {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--300);
  border-radius: var(--pf-v5-global--BorderRadius--lg);
  color: var(--pf-v5-global--Color--100);
  position: relative;
  z-index: 10;
  height: 18px;
  width: 18px;
}

.co-sysevent--warning .co-sysevent-icon,
.co-sysevent--warning .co-sysevent__box {
  border-color: var(--pf-v5-global--warning-color--100);
}

.co-sysevent--warning .co-sysevent__icon-line {
  background-color: var(--pf-v5-global--warning-color--100);
}

.co-sysevent-stream {
  padding: 60px 0 50px 0;
  position: relative;
}

.slide-entering {
  left: 100%;
  opacity: 0;
}

.slide-entered {
  left: 0;
  opacity: 1;
  transition: all .5s;
}

.co-sysevent-stream__connection-error {
  color: var(--pf-v5-global--danger-color--100);
}

.co-sysevent-stream__timeline {
  bottom: 30px;
  position: absolute;
  top: 35px;
}

.co-sysevent-stream__status {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 9px;
  left: 40px;
  right: 4px;
}

@media (min-width: 768px) {
  .co-sysevent-stream__status {
    padding-left: 25px;
    left: 45px;
    right: 0;
  }
}

.co-sysevent-stream__status-box-empty {
  border-style: solid;
  border-color: var(--pf-v5-global--BorderColor--300);
  border-width: var(--pf-v5-global--BorderWidth--sm) 0;
}

.co-sysevent-stream__timeline__end-message {
  bottom: 0;
  position: absolute;
  transform: translateY(50%);
  white-space: nowrap;
  z-index: 1;
}

.co-sysevent-stream__timeline--empty .co-sysevent-stream__timeline__end-message {
  display: none;
}

.co-sysevent-stream__timeline__btn {
  margin: 0;
  transform: translate(0%, -100%);
  margin-left: 2px;
}

.co-sysevent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.co-sysevent--transition {
  padding-bottom: 15px;
}

.co-sysevent__box {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  flex: none;
  padding: 10px;
  width: 100%;
}

.co-sysevent__count-and-actions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.co-sysevent__details {
  column-gap: 8px;
  display: flex;
  justify-content: space-between;
}

.co-sysevent__resourcelink {
  display: block;
  flex: 2 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-sysevent__subheader {
  display: flex;
  justify-content: space-between;
  white-space: pre;
}

.co-sysevent__subheader>*:not(:first-child) {
  margin-left: 8px;
}

.co-sysevent__source {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-sysevent__timestamp {
  flex: 1 0 0;
  justify-content: flex-end;
}

.co-sysevent__message {
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-right: -11px;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 11px;
  position: relative;
}

.co-sysevent__icon-box {
  flex: 0 0 100px;
  display: none;
  position: relative;
}

.co-sysevent__icon-box .co-sysevent-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(50% - 17px), calc(-50% + 1px));
}

.co-sysevent__icon-line {
  background-color: var(--pf-v5-global--BorderColor--300);
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
}

@media (min-width: 1200px) {
  .co-sysevent {
    flex-wrap: nowrap;
    margin-left: -40px;
  }

  .co-sysevent__box {
    flex: 1 2 auto;
    border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
    min-width: 0%;
  }

  .co-sysevent__icon-box {
    display: block;
  }

  .co-sysevent-stream {
    padding-top: 50px;
    padding-left: 5px;
  }

  .co-sysevent-stream__timeline {
    border-bottom: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--300);
    border-left: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--300);
    margin-left: 10px;
  }

  .co-sysevent-stream__timeline--empty {
    border: 0;
  }

  .co-sysevent-stream__timeline__btn {
    transform: translate(-50%, -100%);
    margin-left: -2px;
  }

  .co-sysevent-stream__timeline__end-message {
    left: 57px;
  }
}

.co-icon-and-text {
  align-items: baseline;
  display: flex;
}

.pf-v5-c-label__icon .co-icon-and-text {
  display: block;
}

.co-icon-and-text__icon {
  flex-shrink: 0;
  margin-right: 5px;
}

.co-icon-and-text--lg {
  display: block;
}

.co-icon-and-text--lg .co-icon-and-text__icon {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.co-dashboard-icon {
  font-size: 1.2rem;
}

.co-toggle-play.pf-v5-c-button {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--100);
  border-radius: 50%;
  height: 32px;
  margin-right: 15px;
  padding: 0;
  width: 32px;
}

.co-toggle-play.pf-v5-c-button.co-toggle-play--inactive:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "";
}

.co-toggle-play.pf-v5-c-button.co-toggle-play--active:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "";
}

.co-toggle-play.pf-v5-c-button.co-toggle-play--active {
  border-color: var(--pf-v5-global--palette--green-300);
}

.co-m-horizontal-nav__menu {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  display: flex;
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .co-m-horizontal-nav__menu {
    padding-left: .5rem;
  }
}

.co-m-horizontal-nav__menu--within-sidebar {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  margin-bottom: 1.5rem !important;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: visible;
}

@media (min-width: 1200px) {
  .co-m-horizontal-nav__menu--within-sidebar {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.co-m-horizontal-nav__menu--within-sidebar .co-m-horizontal-nav__menu-item {
  font-size: 16px;
  line-height: normal;
}

.co-m-horizontal-nav__menu--within-overview-sidebar {
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
}

.co-m-horizontal-nav__menu-item {
  font-size: 18px;
}

.co-m-horizontal-nav__menu-item a,
.co-m-horizontal-nav__menu-item button {
  background: transparent;
  border: 0;
  color: var(--pf-v5-global--Color--200);
  display: block;
  padding: 6px 1rem 8px;
  position: relative;
  text-decoration: none;
}

.co-m-horizontal-nav__menu-item a:hover,
.co-m-horizontal-nav__menu-item a:focus,
.co-m-horizontal-nav__menu-item button:hover,
.co-m-horizontal-nav__menu-item button:focus {
  color: var(--pf-v5-global--Color--100);
  text-decoration: none;
}

.co-m-horizontal-nav__menu-item a:hover::after,
.co-m-horizontal-nav__menu-item a:focus::after,
.co-m-horizontal-nav__menu-item button:hover::after,
.co-m-horizontal-nav__menu-item button:focus::after {
  background: var(--pf-v5-global--palette--black-400);
}

.co-m-horizontal-nav__menu-item a::after,
.co-m-horizontal-nav__menu-item button::after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 1rem;
  position: absolute;
  right: 1rem;
}

.co-m-horizontal-nav__menu-item.co-m-horizontal-nav-item--active a,
.co-m-horizontal-nav__menu-item.co-m-horizontal-nav-item--active button {
  color: var(--pf-v5-global--link--Color);
}

.co-m-horizontal-nav__menu-item.co-m-horizontal-nav-item--active a::after,
.co-m-horizontal-nav__menu-item.co-m-horizontal-nav-item--active button::after {
  background: var(--pf-v5-global--link--Color);
}

@media (min-width: 768px) {
  .co-m-horizontal-nav__menu-item:last-child a,
  .co-m-horizontal-nav__menu-item:last-child button {
    margin-right: 1rem;
  }
}

.yaml-editor {
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
}

@media (min-width: 768px) {
  .yaml-editor {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .yaml-editor {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.yaml-editor__buttons {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 20px 1rem;
  margin-top: auto;
  z-index: 10;
}

@media (min-width: 768px) {
  .yaml-editor__buttons {
    padding-left: 0;
    padding-right: 0;
  }
}

.yaml-editor__header {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 20px 1rem;
}

@media (min-width: 1200px) {
  .yaml-editor__header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.yaml-editor__header-text {
  margin: 0;
}

.yaml-editor__link {
  display: inline-block;
}

.yaml-editor__links {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
}

@media (max-width: 991px) {
  .yaml-editor__links {
    display: none;
  }
}

.yaml-editor .monaco-editor .margin .current-line {
  background-color: var(--pf-v5-global--BackgroundColor--dark-200);
}

.co-resource-sidebar-item {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 10px 0 20px;
}

.co-resource-sidebar-item:first-child {
  border-top: 0;
  padding-top: 0;
}

.co-resource-sidebar-item__img {
  margin: 20px 0;
}

.graph-bar__chart {
  height: auto;
}

.graph-bar__label {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.graph-donut-subtitle {
  bottom: 20px;
  color: var(--pf-v5-global--palette--black-400);
  line-height: 1.1;
  margin: 0 0 10px;
  position: relative;
  text-align: center;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.graph-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 0;
  overflow: visible;
  padding-top: 15px;
  width: 100%;
}

.graph-wrapper .cos-status-box {
  height: 100%;
  position: relative;
}

.graph-wrapper svg {
  overflow: visible !important;
}

.graph-title {
  color: var(--pf-v5-global--Color--100);
  line-height: 1.4;
  margin: 0 0 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.graph-wrapper--title-center .graph-title {
  text-align: center;
}

.graph-wrapper--gauge {
  max-width: 170px;
  min-width: 135px;
  width: 100%;
}

.graph-wrapper--status {
  margin-bottom: 30px;
}

.graph-status {
  color: var(--pf-v5-chart-color-black-200);
  text-align: center;
}

.graph-status__link {
  text-decoration: none !important;
}

.graph-status__long {
  font-size: 14px;
  line-height: 1.3;
}

.graph-status__short {
  font-size: 26px;
  margin-bottom: 2px;
}

.graph-status__short.graph-status__short--error {
  color: var(--pf-v5-chart-global--danger--Color--100);
}

.graph-status__short.graph-status__short--ok {
  color: var(--pf-v5-chart-color-green-300);
}

.graph-status__short.graph-status__short--warn {
  color: var(--pf-v5-chart-global--warning--Color--200);
}

.co-app-launcher ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.co-app-launcher .pf-v5-c-app-launcher__menu-item-external-icon {
  --pf-v5-c-app-launcher__menu-item-external-icon--Color: var(--pf-v5-c-menu__item-action--Color);
  opacity: 1;
}

.co-cluster-menu {
  max-width: 80vw;
}

@media (min-width: 768px) {
  .co-cluster-menu {
    max-width: 90vw;
  }
}

.co-cluster-selector {
  display: inline-grid !important;
  grid-template-columns: 1fr auto;
  max-width: 140px !important;
}

@media (min-width: 360px) {
  .co-cluster-selector {
    max-width: 180px !important;
  }
}

@media (min-width: 480px) {
  .co-cluster-selector {
    max-width: 300px !important;
  }
}

@media (min-width: 1200px) {
  .co-cluster-selector {
    max-width: 400px !important;
  }
}

.co-cluster-selector::before {
  border: 1px solid var(--pf-v5-global--palette--black-800) !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.pf-v5-theme-dark .co-cluster-selector::before {
  border-color: var(--pf-v5-global--palette--black-700) !important;
}

.co-masthead-icon {
  font-size: 16px;
}

.co-username {
  max-width: 140px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 855px) {
  .co-username {
    max-width: 225px !important;
  }
}

@media (min-width: 992px) {
  .co-username {
    max-width: 275px !important;
  }
}

@media (min-width: 1200px) {
  .co-username {
    max-width: 300px !important;
  }
}

.pf-v5-c-masthead__content .pf-v5-c-notification-badge {
  --pf-v5-c-notification-badge--m-unread--hover--after--BackgroundColor: transparent;
}

.co-overlay {
  background: rgba(0,0,0,.5);
  bottom: 0;
  cursor: default;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1050;
}

.modal-dialog {
  --os-modal-dialog--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  background: var(--os-modal-dialog--BackgroundColor);
}

:where(.pf-v5-theme-dark) .modal-dialog {
  --os-modal-dialog--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.modal-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.modal-body-border {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.modal-body-content {
  height: 100%;
  padding: 0 var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl);
}

.modal-body-content p {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}

.modal-content {
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: 0;
  position: relative;
}

.modal-dialog {
  margin: var(--pf-v5-global--spacer--md);
  max-width: calc(100% - var(--pf-v5-global--spacer--xl));
  outline: 0;
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .modal-dialog {
    margin: 30px auto;
  }

  .modal-dialog:not(.modal-lg) {
    max-width: 600px;
  }
}

@media (min-width: 1200px) {
  .modal-dialog.modal-lg {
    max-width: 70rem;
  }
}

.modal-footer {
  margin-top: 0;
  padding: var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl);
}

.modal-header {
  padding: var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--lg);
}

.modal__inline-resource-link {
  margin: 0;
}

.toleration-modal__field,
.taint-modal__field {
  padding-right: 0;
}

.toleration-modal__field,
.taint-modal__input {
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .toleration-modal__field,
  .taint-modal__input {
    margin-bottom: 10px;
  }
}

.toleration-modal__heading,
.taint-modal__heading {
  margin-bottom: 5px;
}

.toleration-modal__row,
.taint-modal__row {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .toleration-modal__row,
  .taint-modal__row {
    margin-bottom: 24px;
  }
}

.modal-paragraph {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}

.environment-buttons {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding-top: 20px;
}

.co-empty__header {
  padding: 0;
  width: 28px;
}

.pairs-list__action {
  padding: 0;
  width: 28px;
}

.pairs-list__action-icon {
  background: transparent;
  border: 0;
  display: flex;
  padding-left: 1rem !important;
}

.pairs-list__action-icon--reorder {
  cursor: move;
}

.pairs-list__heading {
  margin-bottom: 5px;
}

.pairs-list__row {
  align-items: center;
  display: flex;
  padding-bottom: 15px;
}

.pairs-list__row-dragging {
  background-color: var(--pf-v5-global--BorderColor--300);
  border: 1px dashed #868789;
  border-radius: 4px;
  height: 28px;
  margin-left: 28px;
  margin-bottom: 15px;
  overflow: hidden;
  padding-right: 100px;
  width: calc(83.34% + 11px);
}

.pairs-list__row-dragging div {
  opacity: 0;
}

.pairs-list__value-field,
.pairs-list__name-field {
  padding-right: 0;
}

.pairs-list__value-pair-field {
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

.pairs-list__value-ro-field {
  flex-grow: 1;
  padding-top: 5px;
}

.pairs-list__side-btn {
  cursor: pointer;
}

.pairs-list__span-btns {
  border: 0;
  display: flex;
}

.value-from {
  width: 100%;
}

.value-from .value-from-pair {
  width: 100%;
}

@media (min-width: 992px) {
  .value-from .value-from-pair {
    width: 50%;
  }
}

.value-from .pf-v5-c-dropdown,
.value-from .pf-v5-c-dropdown__toggle {
  width: 100%;
}

.value-from .pf-v5-c-dropdown__menu {
  min-width: 250px;
  width: 100%;
}

@media (max-width: 767px) {
  .value-from__menu {
    left: auto;
    right: 0;
  }
}

.value-from--key {
  padding-top: 5px;
}

.co-terminal>.terminal {
  color: #fff;
  padding: 10px;
  background-color: #030303;
}

.co-terminal>.terminal.fullscreen {
  z-index: 1050;
}

.co-terminal {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #030303;
  position: relative;
}

.co-terminal>.terminal {
  height: 100%;
}

.co-terminal-collapse-link.pf-v5-c-button {
  background: rgba(0,0,0,.75) !important;
  color: #1fa7f8;
  position: fixed;
  right: 18px;
  top: 4px;
  z-index: 1070;
}

.default-overflow {
  overflow: visible !important;
}

.catalog-item-header-pf-subtitle {
  font-size: 13px;
}

.catalog-tile-pf-body .catalog-tile-pf-subtitle {
  font-size: 13px;
}

.co-catalog {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 515px;
}

.co-catalog-item-info {
  margin-bottom: 20px;
}

.co-catalog-tile {
  color: var(--pf-v5-global--Color--100);
}

.co-catalog-tile-view {
  display: flex;
  flex-wrap: wrap;
  --pf-v5-l-gallery--GridTemplateColumns: repeat(auto-fill, 260px) !important;
}

.co-catalog__body {
  min-width: 575px;
}

@media (min-width: 768px) {
  .co-catalog__body {
    min-width: 590px;
  }
}

.co-catalog-item-details {
  align-items: flex-start;
  display: flex;
  margin: 0 0 10px;
}

.co-catalog-item-details__description {
  white-space: pre-wrap;
}

.co-catalog-item-details__kind-label {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.co-catalog-item-details__name {
  margin-bottom: 10px;
}

.co-catalog-item-details__provider,
.co-catalog-item-details__tags {
  margin-bottom: 5px;
}

.co-catalog-item-details__tag {
  color: var(--pf-v5-global--Color--200);
  font-size: 13px;
  margin-right: 5px;
  text-transform: uppercase;
}

.co-catalog-item-icon {
  padding: var(--pf-v5-global--spacer--xs);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
  margin-right: 15px;
}

.co-catalog-item-icon__bg {
  align-items: center;
  display: flex;
  min-height: 40px;
  min-width: 40px;
}

.co-catalog-item-icon__icon {
  font-size: 24px;
}

.co-catalog-item-icon__icon--large {
  font-size: 40px;
}

.co-catalog-item-icon__img {
  max-height: 24px;
  max-width: 24px;
  width: 100%;
}

.co-catalog-item-icon__img[src$=".svg"] {
  width: 24px;
}

.co-catalog-item-icon__img--large {
  max-height: 40px;
  max-width: 40px;
}

.co-catalog-item-icon__img--large[src$=".svg"] {
  width: 40px;
}

.co-catalog-page {
  background: var(--pf-v5-global--BackgroundColor--100);
  border: 1px solid var(--pf-v5-global--BorderColor--300);
  display: flex;
  flex: 1;
  padding-top: 1rem;
}

.co-catalog-page--with-sidebar {
  margin: 0 1rem;
  padding-bottom: 1rem;
}

.co-catalog-page__btn-group__group-by {
  display: inline;
  margin-left: var(--pf-v5-global--spacer--md);
}

.co-catalog-page__content {
  flex: 1 1 auto;
  overflow: hidden;
}

.co-catalog-page__description {
  margin-top: -10px;
  padding: 0 1rem 1rem;
}

@media (min-width: 1200px) {
  .co-catalog-page__description {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-catalog-page__filter {
  display: flex;
  justify-content: space-between;
}

.co-catalog-page__filter-toggle {
  margin-top: 5px;
}

.co-catalog-page__grid {
  background-color: var(--pf-v5-global--BackgroundColor--200);
}

.co-catalog-page__grouped-items {
  margin-bottom: var(--pf-v5-global--spacer--md);
}

.co-catalog-page__group-title {
  margin-bottom: var(--pf-v5-global--spacer--sm);
}

.co-catalog-page__header {
  margin: 0 1.5rem 0 0;
}

.co-catalog-page__heading {
  font-size: 16px;
  margin: 0 1rem 1rem;
}

@media (min-width: 1200px) {
  .co-catalog-page__heading {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.co-catalog-page__hint {
  margin: 0 0 var(--pf-v5-global--spacer--md) 0;
}

.co-catalog-page__input {
  margin: 0 1rem 1.5rem;
}

@media (max-width: 768px) {
  .co-catalog-page__input {
    max-width: 165px;
  }
}

@media (min-width: 1200px) {
  .co-catalog-page__input {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.co-catalog-page__sort {
  display: inline;
  margin-left: var(--pf-v5-global--spacer--md);
}

.co-catalog-page__num-items {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  padding: 0 0 20px;
}

.co-catalog-page__info-icon {
  vertical-align: middle;
  color: var(--pf-v5-global--icon--Color--light);
}

.co-catalog-page__info-icon:hover {
  color: var(--pf-v5-global--icon--Color--dark);
}

.co-catalog-page__overlay {
  border: 0 !important;
}

.co-catalog-page__overlay .properties-side-panel-pf {
  flex: 0 0 auto;
}

.co-catalog-page__overlay--right {
  bottom: 0;
  padding: 0 !important;
  right: 0;
  top: 4.75rem;
}

.co-catalog-page__overlay--right .pf-v5-c-modal-box__body {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  --pf-v5-c-modal-box__body--PaddingTop: 0 !important;
  --pf-v5-c-modal-box__body--PaddingRight: 0 !important;
  --pf-v5-c-modal-box__body--PaddingLeft: 0 !important;
  --pf-v5-c-modal-box__body--last-child--PaddingBottom: 0 !important;
}

.co-catalog-page__overlay--right h1 {
  white-space: normal;
}

.co-catalog-page__overlay-action {
  align-items: baseline;
  display: inline-flex !important;
  margin: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--sm) 0 0;
  overflow-x: hidden;
}

.co-catalog-page__overlay-action-icon {
  flex-shrink: 0;
  font-size: 14px;
  margin-left: 6px;
}

.co-catalog-page__overlay-action-label {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.co-catalog-page__overlay-actions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--pf-v5-global--spacer--md);
  white-space: normal;
}

.co-catalog-page__overlay-header {
  margin-bottom: var(--pf-v5-global--spacer--md);
}

.co-catalog-page__overlay-body {
  margin: 0;
  padding-top: var(--pf-v5-global--spacer--md);
}

@media (min-width: 768px) {
  .co-catalog-page__overlay-body {
    display: flex;
  }
}

.co-catalog-page__overlay-description {
  margin: 20px 0 0;
  white-space: pre-wrap;
}

.co-catalog-page__overlay-description h1,
.co-catalog-page__overlay-description h2,
.co-catalog-page__overlay-description h3 {
  color: var(--pf-v5-global--Color--100);
}

.co-catalog-page__overlay-description h2 {
  font-size: 20px;
}

.co-catalog-page__overlay-description p,
.co-catalog-page__overlay-description li,
.co-catalog-page__overlay-description ol {
  color: var(--pf-v5-global--Color--100);
  font-size: 14px !important;
}

@media (min-width: 768px) {
  .co-catalog-page__overlay-description {
    flex: 1 1 auto;
    margin-left: 20px;
    margin-top: 0;
  }
}

.co-catalog-page__tabs {
  flex: 0 0 220px;
  margin: 0 1rem 0 0;
}

.co-catalog-page__searchfilter {
  display: inline-flex;
}

@media screen and (min-width: 768px) {
  .co-catalog-page__overlay.pf-v5-c-modal-box {
    --pf-v5-c-modal-box--Width: 600px;
  }
}

@media screen and (min-width: 992px) {
  .co-catalog-page__overlay.pf-v5-c-modal-box {
    --pf-v5-c-modal-box--Width: 900px;
  }
}

.co-catalog-tab__empty {
  color: #36373a;
}

.co-modal-ignore-warning {
  height: initial;
}

.co-modal-ignore-warning__checkbox.checkbox {
  margin-bottom: 0;
  padding-top: 15px;
}

.co-modal-ignore-warning__content {
  display: flex;
}

.co-modal-ignore-warning__icon {
  font-size: 30px;
  margin-right: 15px;
  min-width: 30px;
}

.co-modal-ignore-warning__link {
  display: block;
  margin: 10px 0;
}

.properties-side-panel-pf-property-label {
  font-size: 13px;
}

.properties-side-panel-pf-property-value__capability-level {
  color: var(--pf-v5-global--Color--400);
  margin-bottom: 5px;
  position: relative;
}

.properties-side-panel-pf-property-value__capability-level--active {
  color: inherit;
}

.properties-side-panel-pf-property-value__capability-level--active::before {
  display: none;
}

.properties-side-panel-pf-property-value__capability-level::before {
  border: 1px solid var(--pf-v5-global--Color--400);
  border-radius: 10px;
  content: "";
  height: 14px;
  left: -20px;
  position: absolute;
  top: 4px;
  width: 14px;
}

.properties-side-panel-pf-property-value__capability-level:not(:last-child)::after {
  background: var(--pf-v5-global--Color--400);
  content: "";
  height: 6px;
  left: -13px;
  position: absolute;
  top: 21px;
  width: 1px;
}

.properties-side-panel-pf-property-value__capability-level-icon {
  left: -20px;
  position: absolute;
  top: 4px;
}

.properties-side-panel-pf-property-value__capability-levels {
  list-style: none;
}

.build-overview__item {
  display: flex;
  flex-direction: column;
}

.build-overview__item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.build-overview__item-time {
  display: inline-block;
}

.build-overview__status {
  flex-wrap: wrap;
}

.job-overview__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview {
  overflow: hidden;
  position: relative;
}

.overview .overview__main-column {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.overview .overview__sidebar {
  background: #fff;
  bottom: 0;
  box-shadow: var(--pf-v5-global--BoxShadow--md);
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 15px);
  z-index: 5;
}

@media (min-width: 768px) {
  .overview .overview__sidebar {
    max-width: 550px;
  }
}

.overview .overview__sidebar-appear {
  opacity: 0;
  transform: translateX(10%);
}

.overview .overview__sidebar-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 175ms ease-out,transform 225ms ease-out;
}

.overview.overview--sidebar-shown .overview__sidebar {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .overview.overview--sidebar-shown .overview__main-column {
    margin-right: 550px;
  }
}

.overview__sidebar-pane-body {
  padding: 0 20px;
  min-height: 200px;
}

.overview__sidebar-pane-head {
  padding-top: 5px;
}

.overview__sidebar-pane-head .co-m-pane__heading {
  font-size: var(--pf-v5-global--FontSize--xl);
  margin: 0 20px var(--pf-v5-global--spacer--md);
}

.overview__sidebar-pane-head .co-actions-menu {
  margin-top: -3px;
}

.overview__pod-donut-sm {
  width: var(--pf-v5-global--icon--FontSize--lg);
}

.resource-overview {
  height: 100%;
  min-height: 0;
  padding-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.resource-overview__body {
  display: flex;
  flex-wrap: wrap;
  gap: var(--pf-v5-global--spacer--lg);
}

.resource-overview__details,
.resource-overview__summary {
  flex: 1;
  min-width: 200px;
}

.resource-overview__pod-counts {
  width: 100%;
}

.sidebar__section-heading {
  font-size: 18px;
  margin: 30px 0 10px;
}

.sidebar__section-view-all {
  font-size: 14px;
  margin-left: var(--pf-v5-global--spacer--sm);
}

.sidebar__section-owner-operator-heading {
  display: flex;
}

.sidebar__section-owner-reference-operator {
  padding-left: var(--pf-v5-global--spacer--sm);
}

.co-deploy-image__name {
  margin-top: 15px;
}

.co-deploy-image__namespace .pf-v5-c-dropdown {
  width: 100%;
}

.co-image-name-results {
  border-bottom: 1px solid var(--pf-v5-global--BorderColor--300);
  border-top: 1px solid var(--pf-v5-global--BorderColor--300);
  margin: 30px 0;
  padding: 30px 0;
}

.co-image-name-results__heading .co-timestamp {
  display: inline-flex;
}

.co-image-name-results__heading small {
  margin-left: 7px;
}

.co-image-name-results__loading {
  text-align: center;
}

.co-image-name-results__loading-error {
  margin-bottom: 0;
  margin-top: 10px;
}

.co-image-name-results__loading-heading {
  margin: 0;
}

.co-instantiate-template-form .pf-v5-c-dropdown {
  width: 100%;
}

.co-instantiate-template-form__button-bar {
  margin-top: 10px;
}

.co-create-route__alert {
  margin-bottom: 0;
}

.co-create-route__add-service-btn {
  margin-bottom: 15px;
}

.co-create-route__weight-label {
  display: block;
}

.create-storage-class-form__checkbox {
  width: inherit;
}

.co-resource-quota-chart-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.co-resource-quota-icon {
  padding-left: 34px;
}

.co-resource-quota-scope__description {
  font-size: 12px;
}

.cluster-channel-modal__dropdown .pf-v5-c-dropdown {
  width: 100%;
}

.co-channel {
  align-items: center;
  display: flex;
  font-size: 11px;
}

.co-channel-line {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
  min-width: 50px;
  width: 100%;
}

.co-channel-line:before {
  background-color: var(--pf-v5-global--disabled-color--100);
  content: "";
  height: 4px;
  position: absolute;
  width: 100%;
}

.co-channel-path--current .co-channel-line:before {
  background-color: var(--pf-v5-global--primary-color--100);
}

.co-channel-line:last-child::after {
  background: transparent;
  border: 8px solid transparent;
  border-left-color: var(--pf-v5-global--disabled-color--100);
  border-width: 8px 12px;
  content: "";
  position: absolute;
  right: -15px;
}

.co-channel-path--current .co-channel-line:last-child::after {
  border-left-color: var(--pf-v5-global--primary-color--100);
}

.co-channel--end-of-life .co-channel-line:last-child::after {
  background-color: var(--pf-v5-global--primary-color--100);
  border: 0;
  height: 16px;
  right: 0px;
  width: 3px;
}

.co-channel-more-versions {
  background: var(--pf-v5-c-drawer__content--BackgroundColor) !important;
  border: 1px solid !important;
  border-radius: 15px !important;
  display: inline-block !important;
  font-size: 11px !important;
  font-weight: bold !important;
  justify-content: center;
  padding: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm) !important;
  position: absolute !important;
}

.co-channel-more-versions::after {
  display: none;
}

.co-channel-more-versions:hover,
.co-channel-more-versions:focus {
  background: var(--pf-v5-global--primary-color--100) !important;
  border-color: var(--pf-v5-global--primary-color--100) !important;
  color: var(--pf-v5-c-drawer__content--BackgroundColor) !important;
}

.co-channel-more-versions:focus {
  outline: 0 !important;
}

.co-channel-name {
  color: var(--pf-v5-global--disabled-color--100);
  padding: 0 10px 0 20px;
  min-width: 150px;
  white-space: nowrap;
}

.co-channel-name--current {
  color: inherit;
  padding-top: 20px;
}

.co-channel-path {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.co-channel-path--current {
  margin-top: 20px;
}

.co-channel-start::before {
  position: absolute;
  right: 0;
  width: 25%;
}

.co-channel-switch {
  position: absolute;
  top: -28px;
  width: 100%;
  height: 60px;
  z-index: 1;
}

.co-channel-switch::after,
.co-channel-switch::before {
  background: linear-gradient(to left bottom, transparent 50%, var(--pf-v5-global--disabled-color--100) 50%);
  content: "";
  display: block;
  height: 60px;
  position: absolute;
  right: 0;
  top: -3px;
  width: 50%;
}

.co-channel-switch::after {
  background: linear-gradient(to left bottom, transparent 50%, var(--pf-v5-c-drawer__content--BackgroundColor) 50%);
  right: 3px;
  top: 0;
}

.co-channel-version {
  display: flex;
  flex-direction: column;
  height: 35px;
  justify-content: flex-end;
  line-height: 1;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  top: -20px;
  word-break: break-word;
}

.co-channel-version--current {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.co-channel-version--update-blocked {
  align-items: flex-end;
  flex-direction: row;
}

.co-channel-version__warning-icon {
  flex-shrink: 0;
}

.co-channel-version-dot {
  background: var(--pf-v5-global--primary-color--100) !important;
  border-radius: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  position: absolute;
  width: 16px !important;
  z-index: 2;
}

.co-channel-version-dot::after {
  background: var(--pf-v5-c-drawer__content--BackgroundColor) !important;
  border: 2px solid var(--pf-v5-c-drawer__content--BackgroundColor) !important;
  border-radius: 12px !important;
  bottom: 2px !important;
  content: "";
  left: 2px !important;
  position: absolute;
  right: 2px !important;
  top: 2px !important;
}

.co-channel-version-dot--current::after {
  background: transparent !important;
}

.co-channel-version-dot:focus {
  outline: 0 !important;
}

.co-channel-version-dot--update-blocked {
  background: var(--pf-v5-global--warning-color--100) !important;
}

.co-cluster-settings {
  border-width: 1px;
  max-width: 800px;
}

.co-cluster-settings,
.co-cluster-settings__section {
  border-color: var(--pf-v5-global--BorderColor--300);
  border-style: solid;
}

.co-cluster-settings__details {
  padding-right: var(--pf-v5-global--spacer--lg);
}

@media (min-width: 480px) {
  .co-cluster-settings__details--status {
    max-width: 50%;
  }
}

.co-cluster-settings__row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .co-cluster-settings__row {
    flex-wrap: wrap;
  }
}

.co-cluster-settings__section {
  align-items: center;
  border-width: 1px 0 0 1px;
  flex: 1 1 auto;
  padding: var(--pf-v5-global--spacer--lg) 0 var(--pf-v5-global--spacer--lg) var(--pf-v5-global--spacer--lg);
  margin-left: -1px;
  margin-top: -1px;
}

.co-cluster-settings__section--current {
  flex-grow: 0;
}

.co-cluster-settings__updates-graph {
  padding: 0 var(--pf-v5-global--spacer--lg) 0 0;
}

@media (max-width: 400px) {
  .co-cluster-settings__updates-graph {
    display: none;
  }
}

.co-cluster-settings__updates-group {
  margin: 0 0 var(--pf-v5-global--spacer--lg) 0;
}

.co-cluster-settings__updates-group--divided {
  border-top: 1px solid var(--pf-v5-global--BorderColor--300);
  padding-top: var(--pf-v5-global--spacer--lg);
}

.co-cluster-settings__updates-group--divided~.co-cluster-settings__updates-group--divided {
  border-top: 0;
  padding-top: 0;
}

.co-cluster-settings__updates-progress:not(:empty) {
  padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg) 0 0;
}

.co-cluster-settings__updates-type {
  margin: 0 0 var(--pf-v5-global--spacer--xs) 0;
}

.co-update-status {
  padding-bottom: 5px;
}

.co-cluster-paragraph {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}

.co-search__accordion {
  margin-bottom: var(--pf-v5-global--spacer--lg);
}

@media (min-width: 768px) {
  .co-search__accordion .co-search__accordion-toggle {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-search__accordion .co-m-pane__filter-bar {
  margin-top: 1rem;
}

.co-search__accordion h3 {
  line-height: normal;
  margin-bottom: 0;
}

.co-search__accordion .pf-v5-c-accordion__expanded-content-body {
  --pf-v5-c-accordion__expanded-content-body--PaddingRight: 0 !important;
  --pf-v5-c-accordion__expanded-content-body--PaddingLeft: 0 !important;
}

.co-search__accordion .pf-v5-c-accordion__expanded-content-body:before,
.co-search__accordion .pf-v5-c-accordion__toggle:before {
  display: none;
}

.co-search__filter-input {
  width: 100%;
}

.co-notification-drawer {
  overflow-y: hidden !important;
  z-index: 310 !important;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.pf-v5-c-drawer__content {
  --pf-v5-c-drawer__content--ZIndex: auto;
}

.co-radio-group--inline {
  display: flex;
  align-items: flex-start;
}

.co-radio-group .co-radio-group__label {
  margin-right: 15px;
}

.co-filter-dropdown__item {
  display: inline-flex;
  pointer-events: none;
}

.co-filter-dropdown__list-item {
  list-style: none;
}

.co-filter-dropdown-group>ul {
  margin-left: 0;
  padding-left: 0;
}

.co-filter-dropdown-item {
  display: inline-flex;
  margin: var(--pf-v5-global--spacer--xs) 0;
}

.co-filter-dropdown-item__name {
  padding: 0 var(--pf-v5-global--spacer--xs);
}

@media (min-width: 768px) {
  .co-filter-group {
    width: 350px !important;
  }
}

.co-filter-search--full-width {
  flex-grow: 1;
}

.co-filter-search--full-width .has-feedback {
  width: 100%;
}

.co-suggestion-box {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  z-index: 5;
  width: 100%;
}

@media (max-width: 480px) {
  .co-suggestion-box {
    max-width: calc(100% - 95px);
  }
}

@media (min-width: 480px)and (max-width: 768px) {
  .co-suggestion-box {
    max-width: 200px;
  }
}

.co-suggestion-box__suggestions {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  margin-bottom: -19em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 480px)and (max-width: 768px) {
  .co-suggestion-box__suggestions {
    max-width: 200px;
  }
}

.co-suggestion-box__suggestions--shadowed {
  box-shadow: var(--pf-v5-global--BoxShadow--md);
  padding: var(--pf-v5-global--spacer--sm);
}

.co-suggestion-line {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  background-color: var(--pf-v5-global--palette--black-150);
  border-radius: 12px;
  overflow: hidden;
  display: inline;
  align-self: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - var(--pf-v5-global--spacer--sm));
  margin: .15rem 0;
}

.co-conditions__message {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

.co-pvc-donut {
  max-width: 130px;
  max-height: 130px;
  margin-bottom: 20px;
}

.co-virtualized-table .pf-v5-c-table {
  table-layout: fixed;
}

.co-virtualized-table .pf-v5-c-table tbody>tr:first-child::before {
  content: none;
  width: 0 !important;
}

.co-virtualized-table .pf-v5-c-table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.co-virtualized-table .pf-v5-c-table tr>* {
  height: auto;
}

.co-virtualized-table .pf-v5-c-table .pf-v5-c-table__check {
  width: 8.333% !important;
}

@media screen and (max-width: 768px) {
  .co-virtualized-table .pf-v5-c-table .pf-v5-c-table__check {
    width: 16.66% !important;
  }
}

.co-virtualized-table .pf-v5-c-table .pf-v5-c-table__action {
  padding-left: 0px !important;
  width: 5% !important;
}

@media screen and (max-width: 992px) {
  .co-virtualized-table .pf-v5-c-table .pf-v5-c-table__action {
    width: 10% !important;
  }
}

.pf-v5-c-window-scroller.ReactVirtualized__VirtualGrid,
.pf-v5-c-window-scroller .ReactVirtualized__VirtualGrid,
.pf-v5-c-window-scroller .ReactVirtualized__VirtualGrid__innerScrollContainer {
  overflow: visible !important;
}

.details-item {
  display: inline-block;
  margin-bottom: var(--pf-v5-global--spacer--lg);
}

.details-item__label {
  font-weight: bold;
}

.details-item__popover-button {
  color: inherit !important;
  font-weight: inherit !important;
  margin-bottom: 3px;
  background-image: linear-gradient(to right, var(--pf-v5-c-button--m-plain--Color) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
  padding: 0 !important;
}

.details-item__popover-button:focus,
.details-item__popover-button:hover {
  background-image: linear-gradient(to right, var(--pf-v5-c-button--m-tertiary--Color) 33%, rgba(255, 255, 255, 0) 0%);
}

.details-item__value--group {
  border: 1px solid var(--pf-v5-global--BorderColor--300);
  margin-top: var(--pf-v5-global--spacer--xs);
  padding: var(--pf-v5-global--spacer--md);
}

.details-item__value--group dd:last-child {
  margin-bottom: 0 !important;
}

.details-item__value--labels {
  padding: var(--pf-v5-global--spacer--xs);
}

.resource-metrics-dashboard__card-body {
  align-items: stretch;
  display: flex;
  height: 300px;
  justify-content: center;
}

.resource-metrics-dashboard__card-body .query-browser__wrapper {
  border: none;
  margin: auto;
  padding: 0;
}

.resource-metrics-dashboard__card-body>.graph-wrapper {
  margin: auto;
  padding: 0;
}

.resource-metrics-dashboard__card-body .graph-empty-state {
  min-height: unset;
}

.configmap-form .co-file-dropzone__textarea {
  min-height: 80px;
}

.co-overview-dashboard__activity-body {
  max-height: 48rem;
}

.co-overview-status__health.pf-v5-l-gallery {
  width: 100%;
  --pf-v5-l-gallery--GridTemplateColumns: repeat(auto-fill, minmax(150px, auto));
}

.co-project-dashboard__activity-body {
  max-height: 46rem;
}

.co-project-dashboard-details-card__description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

:root {
  --co-global--palette--blue-400: var(--pf-v5-global--palette--blue-400);
  --co-global--palette--purple-600: var(--pf-v5-global--palette--purple-600);
  --co-global--palette--orange-400: var(--pf-v5-global--palette--orange-400);
  --co-global--palette--purple-700: var(--pf-v5-global--palette--purple-700);
  --co-global--dark--palette--blue-400: var(--pf-v5-global--palette--blue-200);
  --co-global--dark--palette--purple-600: var(--pf-v5-global--palette--purple-200);
  --co-global--dark--palette--orange-400: var(--pf-v5-global--palette--orange-200);
  --co-global--dark--palette--purple-700: var(--pf-v5-global--palette--purple-200);
  --co-skeleton--Color: var(--pf-v5-global--palette--black-150);
  --co-skeleton--Color--300: var(--pf-v5-global--palette--black-300);
  --co-skeleton--dark--Color: var(--pf-v5-global--palette--black-600);
  --co-skeleton--dark--Color--300: var(--pf-v5-global--palette--black-700);
}

:root:where(.pf-v5-theme-dark) {
  --co-global--palette--blue-400: var(--co-global--dark--palette--blue-400);
  --co-global--palette--purple-600: var(--co-global--dark--palette--purple-600);
  --co-global--palette--orange-400: var(--co-global--dark--palette--orange-400);
  --co-global--palette--purple-700: var(--co-global--dark--palette--purple-600);
  --co-skeleton--Color: var(--co-skeleton--dark--Color);
  --co-skeleton--Color--300: var(--co-skeleton--dark--Color--300);
}

